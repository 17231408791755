// These styles are required by WordPress.org Theme Check
// REQUIRED: .sticky css class is needed in your theme css.
// REQUIRED: .bypostauthor css class is needed in your theme css.
:root {
  --color-border-sticky: #d7e4f2;
  --color-bypostauthor: rgb(42 45 62 / .5);
}

.sticky {
  border: 1px dashed var(--color-border-sticky);
  margin-bottom: 2rem;
  padding: 2rem;
}

.comment-list li.bypostauthor,
.bypostauthor {
  border: 1px dashed var(--color-bypostauthor);
  padding: 2rem;
}

// WordPress captions
// REQUIRED: .wp-caption css class is needed in your theme css.
// REQUIRED: .wp-caption-text css class is needed in your theme css.
.wp-caption {
  max-width: 100%;

  img[class*="wp-image-"] {
    display: block;
    margin: 0 auto;
  }

  .wp-caption-text {
    font-style: italic;
    margin-bottom: 20px;
    padding: 1rem 0;
    text-align: left;
  }
}
