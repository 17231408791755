.development-nav-bar {
    background: var(--color-dark-blue);
    .nav-bar-wrap{
        padding: 0 200px;
        border-top: 2px solid var(--color-light-blue);
        border-bottom: 2px solid var(--color-light-blue);
        @include media-breakpoint-down(xxxl) {
            padding: 0 20px;
        }
        @include media-breakpoint-down(xl) {
            padding: 0 30px;
        }
        @include media-breakpoint-down(lg) {
            padding: 20px;
        }
        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            li{
                position: relative;
                width: 100%;
                overflow: hidden;
                padding: 20px 0;
                display: flex;
                justify-content: center;
                align-items: center;
                @include media-breakpoint-down(xl) {
                    padding: 20px 30px;
                }
                &.active{
                    &::before{
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 6%;
                        width: 88%;
                        height: 100%;
                        background-color: var(--color-light-blue);
                        transform: skewX(-30deg);
                        @include media-breakpoint-down(xl) {
                            left: 12%;
                            width: 77%;
                        }
                    }

                }
                .content{
                    position: relative;
                    z-index: 1;
                    text-align: center;
                    a{
                        font-family: var(--font-lato);
                        font-size: 18px;
                        font-weight: bold;
                        letter-spacing: 1px;
                        color: var(--color-white);
                        text-transform: uppercase;
                        text-decoration: none;
                        @include media-breakpoint-down(xxl) {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        select{
            padding: 16px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23ffffff'/%3E%3C/svg%3E%0A");
            background-position: right 16px center;
            border: none;
            color: var(--color-white);
            option{
                color: var(--color-dark-blue);
                background: var(--color-white);
            }
            &:focus{
                outline: 0;
            }
            &:focus-visible{
                outline: 0;
            }
        }
    }
}