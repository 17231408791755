.developments-template-template-developments-contact{
    .development-nav-bar{
        background: linear-gradient(90deg, var(--color-dark-blue) 80%, var(--color-green) 0%);
        @include media-breakpoint-down(lg) {
            background: var(--color-dark-blue);
        }
        .nav-bar-wrap{
            border-top: 2px solid var(--color-green);
            border-bottom: 2px solid var(--color-green);
            li{
                &.active{
                    &::before{
                        background-color: var(--color-green);
                    }
                }
            }
            select{
                background-color: var(--color-green);
            }
        }
    }
    .hero{
        min-height: 675px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(12, 41, 60, 0.5);
        background-blend-mode: multiply;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xl) {
            min-height: 416px;
            .container{
                padding: 0;
            }
        }
        h1{
            font-size: 64px;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            margin: 0 auto 20px;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
                padding: 0 16px;
                margin-top: 0px;
            }
        }
        p{
            font-size: 20px;
            color: var(--color-white);
            text-align: center;
            max-width: 750px;
            margin: 0 auto 0;
            a{
                color: var(--color-green);
                text-decoration: underline;
            }
            @include media-breakpoint-down(xl) {
                font-size: 18px;
                max-width: 100%;
                padding: 0 20px;
            }
        }
        .button{
            text-align: center;
            margin-top: 30px;
            @include media-breakpoint-down(xl) {
                margin-top: 20px;
            }
        }
    }
    .seperators{
        display: none;
        @include media-breakpoint-down(xl) {
            display: block;
            margin: 40px auto;
            border-top: 2px solid var(--color-dark-pink);
            opacity: 1;
            max-width: 90%;
        }
    }
    .contact{
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='730' height='459' viewBox='0 0 730 459'%3E%3Cpath id='Path_426' data-name='Path 426' d='M730,416.276v-459H0Z' transform='translate(730 416.276) rotate(180)' fill='%23f5d9d4'/%3E%3C/svg%3E%0A");
        background-position: left bottom;
        background-repeat: no-repeat;
        padding: 100px 0 65px;
        @include media-breakpoint-down(xl) {
            background: none;
            padding: 0;
        }
        .form-side{
            background-color: var(--color-dark-blue);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='254' height='160' viewBox='0 0 254 160'%3E%3Cpath id='Path_394' data-name='Path 394' d='M254-42.724v160H0Z' transform='translate(0 42.724)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
            background-position: right bottom;
            background-repeat: no-repeat;
            .form-heading{
                padding: 20px 0;
                text-align: center;
                background-color: var(--color-light-blue);
                @include media-breakpoint-down(xl) {
                    padding: 15px 0;
                }
                h2{
                    font-weight: bold;
                    font-size: 32px;
                    color: var(--color-white);
                    margin: 0;
                    @include media-breakpoint-down(xl) {
                        font-size: 24px;
                    }
                }
            }
            .form-content{
                
                padding: 40px 80px 140px;
                @include media-breakpoint-down(xl) {
                    padding: 30px 16px 20px;
                }
                .nav-tabs{
                    .nav-item{
                        width: 50%;
                        .nav-link{
                            width: 100%;
                            background-color: transparent;
                            border: 2px solid var(--color-green);
                            color: var(--color-white);
                            font-family: var(--font-lato);
                            font-size: 16px;
                            font-weight: bold;
                            letter-spacing: 1px;
                            text-transform: uppercase;
                            border-radius: 0;
                            position: relative;
                            padding: 30px 0;
                            @include media-breakpoint-down(xl) {
                                padding: 17px 0px;
                                font-size: 12px;
                            }
                            &.active{
                                background-color: var(--color-green);
                                &::after{
                                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='24' viewBox='0 0 35 24'%3E%3Cpath id='Polygon_5' data-name='Polygon 5' d='M17.5,0,35,24H0Z' transform='translate(35 24) rotate(180)' fill='%23168f4f'/%3E%3C/svg%3E%0A");
                                    position: absolute;
                                    left: 50%;
                                    bottom: -25px;
                                    transform: translateX(-50%);
                                }
                            }
                        }
                    }
                }
                .tab-pane{
                    padding: 85px 38px 0;
                    @include media-breakpoint-down(xl) {
                        padding: 40px 0px 0px;
                    }
                    p{
                        color: var(--color-white);
                        font-size: 14px;
                        margin-bottom: 10px;
                        @include media-breakpoint-down(xl) {
                            text-align: center;
                        }
                        strong{
                            font-family: var(--font-heading);
                            font-weight: bold;
                            font-size: 24px;
                            @include media-breakpoint-down(xl) {
                                font-size: 20px;
                                line-height: 26px;
                            }
                       } 
                    }
                    .gform_wrapper{
                        margin-top: 25px;
                        .gfield_validation_message{
                            font-family: var(--font-paragraph) !important;
                            font-size: 16px  !important;
                            font-weight: 500  !important;
                            margin: 0  !important;
                        }
                        .gform_validation_errors{
                            .gform_submission_error{
                                font-family: var(--font-paragraph);
                                font-size: 16px  !important;
                                font-weight: 500  !important;
                                margin: 0  !important;
                            }
                        }
                        form{
                           .gform-body,
                           .gform_body{
                                .gform_fields{
                                    .gfield{
                                        .gfield_label,
                                        .gform-field-label{
                                            color: var(--color-white);
                                            font-weight: normal;
                                            a{
                                                color: var(--color-white);
                                            }
                                        }
                                        .ginput_container{
                                            input,
                                            select,
                                            textarea{
                                                border: 1px solid var(--color-white);
                                                color: var(--color-white);
                                                option{
                                                    color: var(--color-dark-blue);
                                                }
                                            }
                                            select{
                                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23fff'/%3E%3C/svg%3E%0A");
                                                background-position: right 24px center;
                                            }
                                            .gfield_checkbox{
                                                display: flex;
                                                @include media-breakpoint-down(xl) {
                                                    display: block;
                                                  }
                                                label{
                                                    margin: 0;
                                                }
                                                .gchoice{
                                                    @include media-breakpoint-down(xl) {
                                                        width: 50%;
                                                        display: inline-flex;
                                                    }
                                                    input{
                                                        &:checked{
                                                            border: 1px solid var(--color-green);
                                                            color: var(--color-green);
                                                        &::before{
                                                            background-color: var(--color-green);
                                                            border: 1px solid var(--color-green);
                                                            color: var(--color-green);
                                                        }
                                                        }
                                                    }
                                                }
                                            }
                                            &.ginput_container_consent{
                                                input{
                                                  &:checked{
                                                    &::before{
                                                      background-color: var(--color-green);
                                                    }
                                                  }
                                                }
                                            }
                                        }
                                    }
                                }
                            } 
                            .gform_footer{
                                .gform_button{
                                    background-color: var(--color-light-blue);
                                    color: var(--color-white);
                                    border: 1px solid var(--color-light-blue) !important;
                                    @include media-breakpoint-down(xl) {
                                        width: 100%;
                                    }
                                    &:hover{
                                        background-color: var(--color-white);
                                        color: var(--color-light-blue);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .details-side{
            background-color: #FCF2F1;
            .details-heading{
                padding: 20px 0;
                text-align: center;
                background-color: var(--color-dark-pink);
                @include media-breakpoint-down(xl) {
                    padding: 15px 0;
                }
                h2{
                    font-weight: bold;
                    font-size: 32px;
                    color: var(--color-dark-blue);
                    margin: 0;
                    @include media-breakpoint-down(xl) {
                        font-size: 24px;
                    }
                }
            }
            .details-content{
                padding: 65px 40px 40px;
                @include media-breakpoint-down(xl) {
                    padding: 32px 20px 40px;
                }
                .phone{
                    padding: 0 20px 40px;
                    border-bottom: 2px solid var(--color-dark-pink);
                    @include media-breakpoint-down(xl) {
                        padding: 0 0px 20px;
                        border: none;
                    }
                    p{
                        margin-bottom: 35px;
                    }
                    svg{
                        max-width: 100%;
                        @include media-breakpoint-down(xl) {
                            max-width: 36px;
                        }
                    }
                    a{
                        font-family: var(--font-heading);
                        font-size: 20px;
                        margin: 0;
                        font-weight: bold;
                        color: var(--color-light-blue);
                        text-decoration-color: var(--color-light-blue);
                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                        }
                    }
                }
                .address{
                    padding: 40px 30px 30px;
                    border-bottom: 2px solid var(--color-dark-pink);
                    @include media-breakpoint-down(xl) {
                        padding: 0 0px 20px;
                    }
                    svg{
                        max-width: 100%;
                        @include media-breakpoint-down(xl) {
                            max-width: 24px;
                        }
                    }
                    h3{
                        font-weight: bold;
                        font-size: 20px;
                        margin: 10px 0 15px;
                    }
                    p{
                        font-size: 18px;
                        margin-bottom: 20px;
                    }
                    a{
                        display: block;
                        font-family: var(--font-paragraph);
                        font-size: 20px;
                        color: var(--color-light-blue);
                        margin: 0;
                        text-decoration-color: var(--color-light-blue);
                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                        }
                        @include media-breakpoint-down(md) {
                            text-align: center;
                        }
                        &:first-of-type{
                            margin-bottom: 15px;
                        }
                    }
                }
                .notice{
                    padding-bottom: 40px;
                    border-bottom: 2px solid var(--color-dark-pink);
                    @include media-breakpoint-down(xl) {
                        padding-bottom: 50px;
                    }
                    .content-wrapper{
                        background-color: var(--color-dark-blue);
                        padding: 20px 32px;
                        @include media-breakpoint-down(xl) {
                            padding: 20px 24px;
                        }
                        p{
                            font-family: var(--font-heading);
                            font-weight: bold;
                            font-size: 18px;
                            color: var(--color-white);
                            a{
                                text-decoration: none;
                                color: var(--color-green);
                            }
                            strong{
                                color: var(--color-green);
                            }
                            @include media-breakpoint-down(xl) {
                                font-size: 16px;
                                text-align: center;
                            }
                        }
                    }
                }
                .hours{
                    padding: 40px 30px 30px;
                    @include media-breakpoint-down(xl) {
                        padding: 24px 0px 14px;
                    }
                    .hours-row{
                        @include media-breakpoint-down(xl) {
                            margin-top: 20px;
                            .col-12{
                                padding: 0;
                                .col-6{
                                    padding: 0;
                                }
                            }
                        }
                    }
                    svg{
                        max-width: 100%;
                        @include media-breakpoint-down(xl) {
                            max-width: 36px;
                        }
                    }
                    h3{
                        font-weight: bold;
                        font-size: 20px;
                        margin: 10px 0 15px;
                    }
                    p{
                        margin-bottom: 10px;
                        font-size: 16px;
                    }
                }
                .map-wrapper{
                    position: relative;
                    #mapContainer{
                        min-width: 100%;
                        min-height: 400px;
                        .custom-zoom-buttons-container{
                            position: absolute;
                            top: 32px;
                            right: 32px;
                            display: flex;
                            flex-direction: column;
                            @include media-breakpoint-down(xl) {
                                display: none;
                            }
                            .custom-zoom-button{
                                width: 48px;
                                height: 48px;
                                margin-bottom: 15px;
                                &.zoom-in{
                                    &::before{
                                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cg id='Group_413' data-name='Group 413' transform='translate(-1453 -3378)'%3E%3Ccircle id='Ellipse_14' data-name='Ellipse 14' cx='24' cy='24' r='24' transform='translate(1453 3378)' fill='%23006e88'/%3E%3Cpath id='Path_210' data-name='Path 210' d='M18.034,14.856a9.744,9.744,0,1,0-3.228,3.223l5.562,5.562A2.283,2.283,0,1,0,23.6,20.413Zm-3.426-.2a6.89,6.89,0,1,1,0-9.744A6.892,6.892,0,0,1,14.608,14.658Zm-3.732-6.11h2.936v2.239H10.876v2.936H8.636V10.787H5.7V8.548H8.641V5.612h2.239V8.548Z' transform='translate(1464.871 3389.821)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                                    }
                                }
                                &.zoom-out{
                                    &::before{
                                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 48 48'%3E%3Cg id='Group_414' data-name='Group 414' transform='translate(-1578 -3378)'%3E%3Ccircle id='Ellipse_15' data-name='Ellipse 15' cx='24' cy='24' r='24' transform='translate(1578 3378)' fill='%23006e88'/%3E%3Cpath id='Path_211' data-name='Path 211' d='M18.414,15.118a9.944,9.944,0,1,0-3.3,3.3L20.8,24.093a2.331,2.331,0,0,0,3.3-3.3Zm-3.5-.2a7.035,7.035,0,1,1,0-9.949A7.038,7.038,0,0,1,14.916,14.916ZM6.461,8.445h7V11.2h-7Z' transform='translate(1589.871 3389.871)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                                    }
                                }
                                @include media-breakpoint-down(xl) {
                                    &:last-of-type{
                                        margin: 0;
                                    }
                                }
                            }
                        }
                    }
                    .google-verification{
                        position: absolute;
                        width: calc(100% - calc(var(--bs-gutter-x) * .5));
                        bottom: 0;
                        left: calc(var(--bs-gutter-x) * .5);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        a{
                            background-color: var(--color-dark-blue);
                            padding: 20px;
                            color: var(--color-white);
                            text-decoration: underline;
                            @include media-breakpoint-down(xl) {
                                font-size: 10px;
                                padding: 10px;
                            }
                        }
                        img{
                            max-width: 115px;
                        }
                    }
                }
            }
        }
    }
}