// WordPress built-in comments with comment_form().
// Custom comment template can be found in inc/comments.php
.comments-area {
  margin-bottom: 0;
  margin-top: 50px;
  overflow: visible;
}

.comment-list {
  list-style: none;
  margin: 0;
  padding: 0;

  // Make sure children don't have list bullets
  .children {
    list-style: none;
  }

  li {
    font-size: var(--font-size-14);
    margin-bottom: 70px;
    margin-top: 0;
    padding: 0;
  }

  p {
    color: var(--color-comment-text);
    font-size: var(--font-size-14);
  }

  .avatar {
    border-radius: 50%;
    float: right;
  }

  .comment-author {
    color: var(--color-comment-author-text);
    font-size: var(--font-size-h4);
    margin-bottom: 10px;
    margin-top: 0;
    padding: 0;
  }

  a,
  .comments-link a {
    box-shadow: none;
    color: var(--color-link-comment);
  }

  .comment-author a {
    color: var(--color-paragraph);
  }

  .comment-time {
    font-size: var(--font-size-14);
    margin: 0;
    padding: 0;

    a {
      color: var(--color-link-comment-time-anchor);
    }

    &:focus,
    &:hover {
      opacity: 1;
    }
  }

  .comment-time svg {
    fill: var(--color-link-comment-time-anchor);
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
}

.comments-link {
  align-items: center;
  display: inline-flex;
  font-size: var(--font-size-14);
  text-align: right;

  a {
    color: var(--color-link-comment);
  }

  &:hover,
  &:focus,
  a:hover,
  a:focus {
    color: var(--color-black);
  }

  svg {
    margin-right: 7px;
    position: relative;
    top: -1px;
  }
}

// Comment form grid
.comment-form {
  @media (min-width: $container-ipad-landscape) {
    display: grid;
    grid-gap: 1em;
    // doiuse-disable
    grid-template-columns: 1fr 1fr;

    /* autoprefixer: off */
    justify-content: center;

    // Make these full width
    .comment-notes,
    .comment-form-comment,
    .form-submit {
      grid-column: 1 / span 2;
    }
  }
}

.comment-respond {
  margin-bottom: 0;
  overflow: visible;

  input {
    @media (max-width: $container-mobile) {
      width: 100%;
    }
  }

  .comment-form-author {
    margin-bottom: 20px;
    margin-top: 0;

    input {
      border-width: 1px;
      padding: 15px;
      width: 100%;
    }
  }

  .comment-form-email {
    clear: right;
    margin-bottom: 20px;
    margin-right: 0;
    margin-top: 0;

    input {
      border-width: 1px;
      padding: 15px;
      width: 100%;
    }
  }

  .comment-form-url {
    margin-bottom: 30px;
    margin-top: 0;
    position: relative;

    input {
      border-width: 1px;
      padding: 15px;
      width: 100%;
    }
  }

  .form-submit {
    clear: both;
    display: block;
    margin-bottom: 0;
    margin-top: 0;
  }

  .comment-form-comment {
    margin-bottom: 20px;
  }

  textarea {
    width: 100%;
  }
}
