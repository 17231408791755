.btn{
    background-color: var(--color-green);
    color: var(--color-white);
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding: 10px 34px;
    border-radius: 0;
    border: 1px solid var(--color-green) !important;
    font-family: var(--font-button);
    &:hover{
        background-color: var(--color-white);
        color: var(--color-green);
    }
    &:active{
        color: var(--color-green);
    }
    &:focus{
        color: var(--color-white);
    }
    &:focus-visible{
        color: var(--color-green);
    }
}