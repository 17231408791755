.rank-math-breadcrumb{
    background-color: #f1f1f1;
    padding: 20px 3rem;
    p{
        margin: 0;
        a, span{
            font-size: 18px;
            &.last{
                font-weight: bold;
            }
        }
    }
}