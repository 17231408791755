kbd {
  background-color: var(--color-kbd-background);
  border: 1px solid var(--color-kbd-border);
  border-radius: 4px;
  box-shadow: 0 1px 0 rgb(22 22 22 / .2), 0 0 0 2px var(--color-white) inset;
  color: var(--color-paragraph);
  display: inline-block;
  line-height: 1.4;
  padding: 0 .5rem;
  text-shadow: 0 1px 0 var(--color-white);
  white-space: nowrap;
}
