.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;

  table {
    max-width: 100%;
    width: 100%;
    margin-bottom: 20px;
    border-collapse: collapse;

    tr {
      td {
        border: 1px solid #3d3d3b;
        padding: 10px 15px;
      }
    }
  }
}
