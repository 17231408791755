// Labels
legend,
label {
  color: var(--color-form-label-text);
  font-size: var(--font-size-input-fields);
  font-weight: var(--font-weight-semibold);
}

// Unset checkbox and radio button labels
input + label {
  // stylelint-disable-next-line font-weight-notation
  font-weight: unset;
}

// Form input elements and textareas
textarea,
input[type="text"],
input[type="tel"],
input[type="url"],
input[type="password"],
input[type="number"],
input[type="month"],
input[type="week"],
input[type="email"],
input[type="search"] {
  background-color: var(--color-background-input-field);
  border-color: var(--color-border-forms);
  border-radius: var(--border-radius-input-field);
  border-style: solid;
  border-width: var(--border-width-input-field);
  color: var(--color-form-text);
  font-family: var(--font-paragraph);
  font-size: var(--font-size-input-fields);
  line-height: var(--form-line-height);
  margin-bottom: 0;
  padding-bottom: var(--form-padding-vertical);
  padding-left: var(--form-padding-horizontal);
  padding-right: var(--form-padding-horizontal);
  padding-top: var(--form-padding-vertical);
  position: relative;
  text-decoration: none;
  transition: background .55s;

  &::placeholder {
    color: var(--color-placeholder);
    opacity: var(--opacity-placeholder);
  }

  &:focus {
    background-color: var(--color-background-input-field);
    border-color: var(--color-border-forms-focus);
    color: var(--color-form-text);

    &::placeholder {
      color: var(--color-placeholder-focus);
      opacity: var(--opacity-placeholder-focus);
    }
  }
}

// Select drop downs
select {
  // doiuse-disable
  appearance: none;
  background-image: url('data:image/svg+xml, %3Csvg width="12" height="9" viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M2.049 1.552L6.22 6.268a.188.188 0 00.28 0l4.173-4.716" stroke="%23212121" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/%3E%3Cdefs%3E%3Cpath fill="none" transform="translate(.861 .45)" d="M0 0h10.544v7.95H0z"/%3E%3C/defs%3E%3C/svg%3E%0A');
  background-position: right var(--gap-select-icon) center;
  background-repeat: no-repeat;
  background-size: 11px auto;
  border-color: var(--color-border-forms);
  border-radius: var(--border-radius-select);
  border-style: solid;
  border-width: var(--border-width-input-field);
  color: var(--color-form-text);
  font-family: var(--font-paragraph);
  font-size: var(--font-size-input-fields);
  line-height: var(--form-line-height);
  max-width: 100%;
  min-width: 0;
  padding-bottom: var(--form-padding-vertical);
  padding-left: var(--form-padding-horizontal);
  padding-right: var(--form-padding-horizontal);
  padding-top: var(--form-padding-vertical);
  text-indent: 1px;
  transition: none;
  width: 100%;
}

// Checkboxes & radio buttons
// Learn about this technique:
// @link https://moderncss.dev/pure-css-custom-checkbox-style/
input[type="radio"],
input[type="checkbox"] {
  appearance: none;
  background-color: var(--color-background-input-field);
  border: 1px solid var(--color-border-forms);
  color: currentColor;
  display: grid;
  // stylelint-disable-next-line property-disallowed-list
  font: inherit;
  height: var(--size-checkbox);
  margin: 0;
  place-content: center;
  width: var(--size-checkbox);
}

// Round style radio buttons
input[type="radio"]::before,
input[type="radio"] {
  border-radius: 50%;
}

// Square style checkboxes
input[type="checkbox"]::before,
input[type="checkbox"] {
  border-radius: var(--border-radius-checkbox);
}

input[type="radio"]::before,
input[type="checkbox"]::before {
  background-color: var(--color-checkbox-checked);
  // Change checkbox color by changing 7effe1 to something else below
  background-image: url('data:image/svg+xml, %3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" heighg="16"%3E%3Cg class="nc-icon-wrapper" stroke-width="3" fill="%237effe1" stroke="%237effe1"%3E%3Cpolyline fill="none" stroke="%237effe1" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" points="1, 9 5, 13 15, 3" data-cap="butt"%3E%3C/polyline%3E%3C/g%3E%3C/svg%3E%0A');
  background-position: center;
  background-size: var(--size-checkbox-marker) var(--size-checkbox-marker);
  border: 1px solid var(--color-checkbox-checked);
  content: '';
  height: var(--size-checkbox);
  opacity: 0;
  transform: scale(.8);
  transition: transform .2s;
  width: var(--size-checkbox);
}

input[type="radio"]:checked::before,
input[type="checkbox"]:checked::before {
  opacity: 1;
  transform: scale(1);
}

input[type="radio"]:checked,
input[type="checkbox"]:checked {
  // Hide element visually on click so it creates a bouncy animation illusion
  background-color: transparent;
  border-color: transparent;
}

input[type="radio"]:hover,
input[type="radio"] + label:hover,
input[type="radio"]:focus,
input[type="radio"] + label:focus,
input[type="checkbox"]:hover,
input[type="checkbox"] + label:hover,
input[type="checkbox"]:focus,
input[type="checkbox"] + label:focus {
  cursor: pointer;
}

.wpcf7-response-output {
  font-family: var(--font-lato);
}
