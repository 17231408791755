.gallery-slider{
    background-color: var(--color-dark-blue);
    margin-bottom: 50px;
    position: relative;
    .slider{
        padding: 0;
        margin-bottom: 30px;
        .slick-slide{
            margin: 0 16px;
            opacity: 0.4;
            @include media-breakpoint-down(xl) {
                margin: 0;
            }
            &.slick-active,
            .slick-center{
                opacity: 1;
            }
            div{
                max-height: 100%;
                img,
                iframe{
                    height: 643px;
                    object-fit: cover;
                    width: 100%;
                    @include media-breakpoint-down(xl) {
                        height: 431px;
                    }
                }
            }
        }
    }
    .navigation{
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: 523px;
        height: 80px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='523' height='80' viewBox='0 0 523 80'%3E%3Cpath id='Union_14' data-name='Union 14' d='M261.5,80H135.676L0,.409,261.5,0,523,.409,387.324,80Z' fill='%230c293c'/%3E%3C/svg%3E%0A");
        background-size: contain;
        background-repeat: no-repeat;
        display: flex;
        align-items: flex-end;
        @include media-breakpoint-down(xl) {
            bottom: -50px;
            align-items: center;
        }
        .slick-arrow{
            font-size: 0;
            top: 50%;
            transform: translateY(-50%);
            height: 32px;
            width: 32px;
            z-index: 2;
            @include media-breakpoint-down(xl) {
                top: 40%;
                width: 20px;
                width: 20px;
            }
            &.slick-prev{
                left: 180px;
                @include media-breakpoint-down(xl) {
                    left: 130px;
                }
                &::before{
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='arrow-right' transform='translate(32 32) rotate(180)'%3E%3Cpath id='Path_61' data-name='Path 61' d='M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30.4A14.4,14.4,0,1,1,30.4,16,14.4,14.4,0,0,1,16,30.4Zm0,0' fill='%23fff'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M1.279.191A.771.771,0,1,0,.264,1.352L5.771,6.17.264,10.989A.771.771,0,1,0,1.279,12.15l6.17-5.4a.772.772,0,0,0,0-1.161Zm0,0' transform='translate(12.953 9.83)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                    opacity: 1;
                }
            }
            &.slick-next{
                right: 180px;
                @include media-breakpoint-down(xl) {
                    right: 145px;
                }
                &::before{
                    content: url("data:image/svg+xml,%3Csvg id='arrow-right' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath id='Path_61' data-name='Path 61' d='M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30.4A14.4,14.4,0,1,1,30.4,16,14.4,14.4,0,0,1,16,30.4Zm0,0' fill='%23fff'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M171.941,128.19a.771.771,0,1,0-1.015,1.161l5.507,4.818-5.507,4.818a.771.771,0,0,0,1.015,1.161l6.17-5.4a.771.771,0,0,0,0-1.161Zm0,0' transform='translate(-157.709 -118.17)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    opacity: 1;

                }
            }
        }
        .numbers{
            text-align: center;
            max-width: 100%;
            p{
                font-size: 24px;
                font-weight: 500;
                letter-spacing: 1px;
                color: var(--color-white);
                margin-bottom: 25px;
                @include media-breakpoint-down(xl) {
                    font-size: 18px;
                }
            }
        }
    }
}