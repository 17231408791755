// Alignments
.editor-styles-wrapper{
  .alignleft > * {
    float: left;
  }

  .alignright > * {
    float: right;
  }

  .alignleft > img {
    margin-bottom: var(--margin-between-paragraphs);
    margin-right: var(--padding-container-horizontal);
    margin-top: var(--margin-between-paragraphs);

    + figcaption {
      margin-top: 0;
    }
  }

  .alignright > img {
    margin-bottom: var(--margin-between-paragraphs);
    margin-left: var(--padding-container-horizontal);
    margin-top: var(--margin-between-paragraphs);

    + figcaption {
      margin-top: 0;
    }
  }

  .alignwide {
    max-width: $width-wide;
    padding-left: var(--padding-container-horizontal);
    padding-right: var(--padding-container-horizontal);
    width: 100%;

    @media (min-width: $width-wide + 40px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .alignfull {
    max-width: $width-full;
    padding-left: 0;
    padding-right: 0;
    width: $width-full;

    &.wp-block-image img {
      border-radius: 0;
    }

    @media (min-width: $width-max-article + 40px) {
      margin-bottom: var(--padding-block);
      margin-top: var(--padding-block);
      max-width: $width-full;
      width: $width-full;
    }
  }
}
