.developments-template-default{
    .development-nav-bar{
        background: linear-gradient(-90deg, var(--color-dark-blue) 80%, var(--color-light-blue) 0%);
        @include media-breakpoint-down(lg) {
            background: var(--color-dark-blue);
        }
        .nav-bar-wrap{
            border-top: 2px solid var(--color-light-blue);
            border-bottom: 2px solid var(--color-light-blue);
            li{
                &.active{
                    &::before{
                        background-color: var(--color-light-blue);
                    }
                }
            }
            select{
                background-color: var(--color-light-blue);
            }
        }
    }
    .rank-math-breadcrumb{
        margin-top: -3rem;
        @include media-breakpoint-down(xxl) {
            
            padding-top: 4rem;
        }
    }
    .introduction{
        margin: 100px 0 80px;
        @include media-breakpoint-down(xl) {
            margin: 80px 0 30px;
            .container{
                padding: 0;
            }
        }
        .content-side{
            @include media-breakpoint-down(xl) {
                padding: 0 15px;
            }
            h1{
                font-size: 48px;
                font-weight: bold;
                color: var(--color-dark-blue);
                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                }
            }
            p{
                strong{
                    font-size: 20px;
                    line-height: 20px;
                    @include media-breakpoint-down(xl) {
                        font-size: 16px;
                    }
                }
                font-size: 16px;
                &.address{
                    font-family: var(--font-heading);
                    font-size: 24px;
                    font-weight: bold;
                    color: var(--color-dark-blue);
                    margin-bottom: 55px;
                    @include media-breakpoint-down(xl) {
                        font-size: 16px;
                        margin-bottom: 20px;
                    }
                }
            }
            .btn{
                margin-top: 25px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                }
            }
        }
        .info-side{
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='545' height='313' viewBox='0 0 545 313'%3E%3Cpath id='Path_457' data-name='Path 457' d='M565,3669.754v313H20Z' transform='translate(-20 -3669.754)' fill='%23f1cdc6'/%3E%3C/svg%3E%0A");
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0;
            .information-box{
                margin: 0 40px 40px 0;
                padding: 35px 25px;
                border: 2px solid var(--color-bronze);
                background-color: var(--color-white);
                @include media-breakpoint-down(xl) {
                    margin: 40px 16px 20px;
                    padding: 20px 16px;
                }
                h2{
                    font-size: 32px;
                    color: var(--color-dark-blue);
                    font-weight: bold;
                    margin-bottom: 15px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                .information-row{
                    border-top: 2px solid var(--color-bronze);
                    padding: 30px 0;
                    align-items: center;
                    @include media-breakpoint-down(xl) {
                        padding: 15px 0;
                    }
                    @include media-breakpoint-down(lg) {
                        padding: 20px 0;
                        &.mobile{
                            padding: 20px 0 10px;
                            &:last-of-type{
                                border: none;
                                padding: 0;
                            }
                            .col-12{
                                padding: 0;
                                .btn{
                                    padding: 15px 34px;
                                }
                            }
                        }
                    }
                    p{
                        font-size: 24px;
                        color: var(--color-dark-blue);
                        margin: 0;
                        @include media-breakpoint-down(xxl) {
                            font-size: 20px;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 16px;
                        }
                        a{
                            color: var(--color-dark-blue);
                            font-weight: 500;
                        }
                    }
                    .btn{
                        width: 300px;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .seperators{
        margin: 0 142px;
        border-top: 2px solid var(--color-dark-pink);
        opacity: 1;
        @include media-breakpoint-down(xl) {
            display: none;
        }
    }
    .area{
        margin: 80px 0;
        @include media-breakpoint-down(xl) {
            margin: 30px 0 0;
            .container{
                padding: 0;
            }
        }
        .image-side{
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='595' height='342' viewBox='0 0 595 342'%3E%3Cpath id='Path_458' data-name='Path 458' d='M20,3669.754v342H615Z' transform='translate(-20 -3669.754)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
            background-position: left bottom;
            background-repeat: no-repeat;
            padding: 0;
            @include media-breakpoint-down(xl) {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='172' height='98.864' viewBox='0 0 172 98.864'%3E%3Cpath id='Path_690' data-name='Path 690' d='M20,3669.754v98.864H192Z' transform='translate(-20 -3669.754)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
            }
            img{
                margin: 0 0px 40px 40px;
                width: 100%;
                object-fit: cover;
                @include media-breakpoint-down(xl) {
                    margin: 0 16px 18px;
                    width: 90%;
                }
            }
        }
        .info-side{
            @include media-breakpoint-down(lg) {
                padding: 30px 16px 0;
            }
            h3{
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 20px;
                @include media-breakpoint-down(xl) {
                    margin-bottom: 10px;
                    font-size: 28px;
                }
            }
            .btn{
                margin-top: 25px;
                width: 300px;
                @include media-breakpoint-down(lg) {
                    width: 100%;
                    margin-top: 10px;
                }
            }
        }
        .seperators{
            display: none;
            @include media-breakpoint-down(xl) {
                display: block;
                margin: 30px 20px 30px;
                border-top: 2px solid var(--color-dark-blue);
            }
        }
    }
    .plots{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1628' height='917' viewBox='0 0 1628 917'%3E%3Cpath id='Path_425' data-name='Path 425' d='M0,917V0H1628Z' transform='translate(1628 917) rotate(180)' fill='%23cce2e7'/%3E%3C/svg%3E%0A");
        padding: 65px 0 50px;
        background-position: right bottom;
        background-repeat: no-repeat;
        @include media-breakpoint-down(xl) {
            padding: 30px 0px;
            background-size: 140%;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 25px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }
        }
        .plot{
            padding: 0 12px;
            .image-wrapper{
                position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    height: 310px;
                    object-fit: cover;
                }
                .image-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 17px 24px 17px 13px;
                    min-width: 307px;
                    background-repeat: no-repeat;
                    p{
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 16px;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .content-wrapper{
                background-color: var(--color-dark-blue);
                padding: 30px 30px 40px;
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 5px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                h4{
                    font-size: 24px;
                    color: var(--color-white);
                    margin-bottom: 10px;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        margin-bottom: 25px;
                    }
                }
                .price{
                    svg{
                        margin: 0 18px 0 0px !important;
                    }
                }
                .plot-information,
                .price,
                .size{
                    margin-top: 20px;
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                    @include media-breakpoint-down(xxl) {
                        p{
                            font-size: 14px;
                        }
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                        p{
                            font-size: 16px;
                        }
                    }
                }
                .size{
                    margin-top: 0;
                }
                .button-row{
                    margin: 20px 0 0;
                    align-items: center;
                    @include media-breakpoint-down(xxl) {
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                }
                .btn{
                    padding: 10px 40px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
        }
        .plot-navigation{
            width: 180px;
            height: 80px;
            align-items: center;
            margin: 0 auto;
            position: relative;
            display: none;
            @include media-breakpoint-down(lg) {
                display: flex;
            }
            .slick-arrow{
                font-size: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 32px;
                width: 32px;
                z-index: 2;
                &.slick-prev{
                    left: 0;
                    &::before{
                        content: url("data:image/svg+xml,%3Csvg id='Group_735' data-name='Group 735' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath id='Path_61' data-name='Path 61' d='M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm0,0' transform='translate(40 40) rotate(180)' fill='%230c293c'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M2.135.306A1.324,1.324,0,0,0,.318.423,1.205,1.205,0,0,0,.44,2.169L9.629,9.9.44,17.63a1.2,1.2,0,0,0-.122,1.746,1.323,1.323,0,0,0,1.816.117l10.3-8.662a1.207,1.207,0,0,0,0-1.863Zm0,0' transform='translate(25.391 29.899) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
                        opacity: 1;
                    }
                }
                &.slick-next{
                    right: 5px;
                    &::before{
                        content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg id='Group_734' data-name='Group 734' transform='translate(73.794 1998.082) rotate(180)'%3E%3Cpath id='Path_61' data-name='Path 61' d='M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm0,0' transform='translate(73.794 1998.082) rotate(180)' fill='%230c293c'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M2.135.306A1.324,1.324,0,0,0,.318.423,1.205,1.205,0,0,0,.44,2.169L9.629,9.9.44,17.63a1.2,1.2,0,0,0-.122,1.746,1.323,1.323,0,0,0,1.816.117l10.3-8.662a1.207,1.207,0,0,0,0-1.863Zm0,0' transform='translate(59.184 1987.982) rotate(180)' fill='%230c293c'/%3E%3C/g%3E%3C/svg%3E%0A");
                        opacity: 1;

                    }
                }
            }
            .numbers{
                text-align: center;
                max-width: 100%;
                p{
                    font-size: 24px;
                    font-weight: 500;
                    letter-spacing: 1px;
                    color: var(--color-dark-blue);
                    margin: 0;
                }
            }
        }
        .button{
            text-align: center;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .btn{
                &.main{
                    max-width: 340px;
                    margin-top: 50px;
                    background-color: var(--color-light-blue);
                    color: var(--color-white);
                    border: 1px solid var(--color-light-blue) !important;
                    &:hover{
                        background-color: var(--color-white);
                        color: var(--color-light-blue);
                    }
    
                }
            }
        }
    }
    .siteplan{
        margin: 80px 0;
        @include media-breakpoint-down(xl) {
            margin: 30px 0 40px;
            .container{
                padding: 0;
            }
        }
        .image-side{
            @include media-breakpoint-down(xl) {
                order: 1;
                padding: 0;
            }
            .zoom-image-wrapper{
                border: 2px solid var(--color-light-blue);
                position: relative;
                .image-wrapper{
                    max-width: 100%;
                    overflow: hidden;
                    position: relative;
                    @include media-breakpoint-down(xl) {
                        height: 620px;
                    }
                    #zoom-image {
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                        cursor: grab;
                        &.dragging{
                            cursor: grabbing;
                        }
                    }
                }
                .controls{
                    position: absolute;
                    top: 32px;
                    right: 32px;
                    display: flex;
                    flex-direction: column;
                    @include media-breakpoint-down(xl) {
                        bottom: 20px;
                        right: 20px;
                        top: auto;
                    }
                    button{
                        background: none;
                        box-shadow: none;
                        border: none;
                        padding: none;
                        margin-bottom: 15px;
                        @include media-breakpoint-down(xl) {
                            &:last-of-type{
                                margin: 0;
                            }
                        }
                    }
                }
            }
            
        }
        .info-side{
            padding: 0;
            @include media-breakpoint-down(xl) {
                order: 12;
                padding: 25px 16px 0;
            }
            h2{
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 20px;
                @include media-breakpoint-down(xl) {
                    font-size: 24px;
                }
            }
            .row{
                flex-direction: row;
                align-items: center;
                margin: 20px 0;
                &:first-of-type{
                    margin-top: 35px;
                }
                svg{
                    max-width: 32px;
                    padding: 0;
                }
                p{
                    max-width: calc(100% - 32px);
                    font-size: 20px;
                    margin: 0;
                }
            }
        }
    }
    .specification{
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='906' height='1047' viewBox='0 0 906 1047'%3E%3Cpath id='Union_70' data-name='Union 70' d='M0,523.5,906,1047V0Z' transform='translate(906 1047) rotate(180)' fill='%23cce2e7'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        display: flex;
        align-items: center;
        .specification-wrapper{
            background-color: var(--color-light-blue);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='865' height='487' viewBox='0 0 865 487'%3E%3Cpath id='Path_226' data-name='Path 226' d='M0,0V487H865Z' transform='translate(0)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: left bottom;
            padding: 65px 240px;
            @include media-breakpoint-down(xxl) {
                padding: 65px 50px;
            }
            @include media-breakpoint-down(xl) {
                padding: 50px 0px;
                background-image: none;
            }
            @include media-breakpoint-down(lg) {
                padding: 0px 0px 50px;
                background-image: none;
            }
            .image-side{
                padding: 0 70px 0 0;
                min-height: 100%;
                @include media-breakpoint-down(lg) {
                    padding: 0px;
                    max-height: 387px;
                }
                img{
                    display: none;
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    &.show{
                        display: block;
                    }
                }
            }
            .accordion-side{
                @include media-breakpoint-down(xl) {
                    padding: 24px 20px 0;
                }
                h2{
                    font-size: 48px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 20px;
                    @include media-breakpoint-down(xl) {
                        font-size: 28px;
                    }
                }
                p{
                    color: var(--color-white);
                    margin: 0;
                }
                .accordion{
                    margin-top: 50px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                    }
                    .accordion-item{
                        border: none;
                        background-color: transparent;
                        color: var(--color-white);
                        border-radius: 0;
                        .accordion-header{
                            border-bottom: 2px solid var(--color-bronze);
                            .accordion-button{
                                border: none;
                                background-color: transparent;
                                color: var(--color-white);
                                border-radius: 0;
                                font-family: var(--font-heading);
                                font-size: 24px;
                                font-weight: bold;
                                padding: 20px 0;
                                @include media-breakpoint-down(xl) {
                                    font-size: 18px;
                                    padding: 10px 0;
                                }
                                &::after{
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='Path_213' data-name='Path 213' d='M173.15,128.37a1.5,1.5,0,1,0-1.975,2.259L181.884,140l-10.71,9.371a1.5,1.5,0,0,0,1.975,2.259l12-10.5a1.5,1.5,0,0,0,0-2.259Zm0,0' transform='translate(151.999 -170.662) rotate(90)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
                                }
                                &:not(.collapsed){
                                    background-color: transparent;
                                    box-shadow: none;
                                    &::after{
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='Path_212' data-name='Path 212' d='M173.15,128.37a1.5,1.5,0,1,0-1.975,2.259L181.884,140l-10.71,9.371a1.5,1.5,0,0,0,1.975,2.259l12-10.5a1.5,1.5,0,0,0,0-2.259Zm0,0' transform='translate(-127.999 185.662) rotate(-90)' fill='%23bd8052'/%3E%3C/svg%3E%0A");
                                        transform: none;
                                    }
                                }
                            }
                        }
                        .accordion-collapse{
                            border-bottom: 2px solid var(--color-bronze);
                            .accordion-body{
                                padding: 35px 0;
                                @include media-breakpoint-down(xl) {
                                    padding: 20px 0;
                                }
                                ul{
                                    li{
                                        font-size: 16px;
                                        color: var(--color-white);

                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .virtual-tour{
        background: rgb(255,255,255);
        background: linear-gradient(-29deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(204,226,231,1) 50%, rgba(204,226,231,1) 100%);
        padding: 80px 0px;
        @include media-breakpoint-down(xl) {
            padding: 35px 0px 45px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            color: var(--color-dark-blue);
            margin-bottom: 20px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }
        }
        iframe{
            min-height: 800px;
            @include media-breakpoint-down(xl) {
                min-height: 550px;
            }
            @include media-breakpoint-down(lg) {
                min-height: 350px;
            }
        }
    }
    .developments{
        background-color: var(--color-off-white);
        padding: 80px 0;
        @include media-breakpoint-down(xl) {
            padding: 25px 0 50px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            text-align: center;
            margin-bottom: 30px;
            @include media-breakpoint-down(xl) {
                font-size: 24px;
            }
            @include media-breakpoint-down(lg) {
                margin: 0;
            }
        }
        .development{
            @include media-breakpoint-down(lg) {
                padding: 24px 0 0;
            }
            .image-wrapper{
                position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    height: 420px;
                    object-fit: cover;
                    @include media-breakpoint-down(xl) {
                        height: 220px;
                    }
                }
                .image-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 17px 24px 17px 13px;
                    min-width: 307px;
                    background-repeat: no-repeat;
                    @include media-breakpoint-down(xl) {
                        width: 224px;
                        min-width: auto;
                        padding: 12px 24px;
                        background-size: contain;
                    }
                    p{
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 16px;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .content-wrapper{
                background-color: var(--color-dark-blue);
                padding: 30px 30px 40px;
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 20px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    @include media-breakpoint-down(xl) {
                        &:last-of-type{
                            margin-top: 15px;
                        }
                    }
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                        @include media-breakpoint-down(xl) {
                            font-size: 16px;
                        }
                    }
                    &.price{
                        svg{
                            margin: 0 25px 0 8px !important;
                        }
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                    @include media-breakpoint-down(xl) {
                        margin: 20px 0;
                    }
                }
                .btn{
                    padding: 10px 34px;
                    @include media-breakpoint-down(xxl) {
                        padding: 10px 24px;
                    }
                    @include media-breakpoint-down(xl) {
                        width: 100%;
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        @include media-breakpoint-down(xl) {
                            margin-top: 10px;
                        }
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
        }
    }
}