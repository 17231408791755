.page-template-template-main-information{

    .hero{
        min-height: 675px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: rgba(12, 41, 60, 0.5);
        background-blend-mode: multiply;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xl) {
            min-height: 416px;
            .container{
                padding: 0;
            }
        }
        h1{
            font-size: 64px;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            margin: 0 auto 10px;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
                padding: 0 16px;
                margin-top: 0px;
            }
        }
        p{
            font-size: 20px;
            color: var(--color-white);
            text-align: center;
            max-width: 750px;
            margin: 0 auto 0;
            line-height: 32px;
            a{
                color: var(--color-light-blue);
                text-decoration: underline;
            }
            @include media-breakpoint-down(xl) {
                font-size: 18px;
                max-width: 100%;
                padding: 0 20px;
            }
        }
    }

    .blue-column-block{
        padding: 40px 0;
        background: linear-gradient(180deg, var(--color-white) 42%, var(--color-off-white) 30%);
        @include media-breakpoint-down(xl) {
            background: var(--color-off-white);
            padding: 30px 14px 40px;
        }
        .blocks-wrapper{
            justify-content: center;
            @include media-breakpoint-down(xl) {
                justify-content: space-around;
            }
            .block{
                
                @include media-breakpoint-down(xl) {
                    padding: 0;
                    margin: 20px 0 0;
                }
                .content-wrapper{
                    background-color: var(--color-dark-blue);
                    padding: 30px;
                    height: 100%;
                    @include media-breakpoint-down(xxl) {
                        padding: 25px;
                    }
                    @include media-breakpoint-down(xl) {
                        padding: 16px;
                    }
                    img{
                        height: 301px;
                        max-width: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center;
                        @include media-breakpoint-down(xxl) {
                            height: 275px;
                        }
                        @include media-breakpoint-down(xl) {
                            height: 193px;
                        }
                    }
                    h3{
                        margin: 30px 0 20px;
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 32px;
                        min-height: 77px;
                        @include media-breakpoint-down(xxl) {
                            font-size: 28px;
                            margin: 20px 0;
                            min-height: 70px;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 23px;
                            min-height: 30px;
                        }
                        @include media-breakpoint-down(lg) {
                            min-height: auto;
                        }
                    }
                    p{
                        font-size: 16px;
                        color: var(--color-white);
                        margin: 0;
                        min-height: 175px;
                        @media (max-width: 1500px) { 
                            min-height: 240px;
                        }
                        @include media-breakpoint-down(xxl) {
                            min-height: 195px;
                        }
                        @include media-breakpoint-down(xl) {
                            min-height: 220px;
                        }
                        @include media-breakpoint-down(lg) {
                            min-height: auto;
                        }
                    }
                    .btn{
                        margin-top: 35px;
                        padding: 10px 35px;
                        @include media-breakpoint-down(xxl) {
                            padding: 10px 70px;
                        }
                        @include media-breakpoint-down(xl) {
                            margin-top: 10px;
                            width: 100%;
                            padding: 10px 52px;
                        }
                    }
                }
            }
        }
    }

    .seperator{
        margin: 60px auto;
        border-top: 2px solid var(--color-pink);
        opacity: 1;
        max-width: 75%;
        @include media-breakpoint-down(xl) {
            display: block;
            max-width: 90%;
            margin: 40px auto 0;
        }
    }

    .video{
        background-color: var(--color-off-white);
        padding: 0 0 65px;
        @include media-breakpoint-down(xl) {
            padding: 0 0 24px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 30px;
            @include media-breakpoint-down(xl) {
                font-size: 24px;
            }
        }
        .video-wrapper{
            position: relative;
            margin-bottom: 50px;
            @include media-breakpoint-down(xl) {
                margin-bottom: 30px;
            }
            video{
                width: 100%;
                @include media-breakpoint-down(xl) {
                    height: 400px;
                    object-fit: cover;
                }
            }
            iframe{
                width: 100%;
                max-width: 100%;
                height: 720px;
                @include media-breakpoint-down(xl) {
                    height: 400px;
                }
            }
            .play-overlay{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                &:hover{
                    cursor: pointer;
                }
            }
        }
        p{
            font-size: 18px;
            margin: 0
        }
        .btn{
            margin-top: 30px;
        }
    }

    .timeline{
        position: relative;
        padding: 40px 0;
        h2{
            margin-bottom: 50px;
            font-size: 48px;
            font-weight: bold;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 24px;
            }
        }
        .timeline-wrapper{
            position: relative;
            .center-line{
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 100%;
                width: 2px;
                background-color: var(--color-pink);
                @include media-breakpoint-down(lg) {
                    left: 16px;
                }
            }
            .step{
                justify-content: space-between;
                @include media-breakpoint-down(xl) {
                    align-items: center;
                }
                @include media-breakpoint-down(lg) {
                    justify-content: flex-end;
                    margin: 30px 0 0;
                }
                &.right{
                    flex-direction: row-reverse;
                    margin: -60px 0;
                    padding-bottom: 60px;
                    @include media-breakpoint-down(xl) {
                        margin: 0;
                        padding-bottom: 0;
                    }
                    @include media-breakpoint-down(lg) {
                        margin: 30px 0 0;
                        flex-direction: row;
                    }
                    .content-side{
                        padding-top: 130px;
                        @include media-breakpoint-down(xl) {
                            padding-top: 0px;
                        }
                        @include media-breakpoint-down(lg) {
                            padding-top: 20px;
                        }
                        &::before{
                            right: -20%;
                            left: auto;
                            top: 155px;
                            @include media-breakpoint-down(xl) {
                                right: -20%;
                                top: 20px;
                            }
                            @include media-breakpoint-down(lg) {
                                right: auto;
                                left: -8%;
                                top: 30px;
                            }
                        }
                    }
                }
                &:first-of-type{
                    @include media-breakpoint-down(lg) {
                        margin: 0;
                    }
                    .content-side{
                        padding-top: 65px;
                        @include media-breakpoint-down(xl) {
                            padding-top: 0px;
                        }
                        @include media-breakpoint-down(lg) {
                            padding-top: 20px;
                        }
                        &::before{
                            top: 85px;
                            @include media-breakpoint-down(xl) {
                                top: 20px;
                            }
                            @include media-breakpoint-down(lg) {
                                top: 30px;
                            }
                        }
                    }
                }
                &:nth-child(2n+2){
                    .image-side{
                        .image-wrapper{
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='368.961' height='213.934' viewBox='0 0 368.961 213.934'%3E%3Cpath id='Path_280' data-name='Path 280' d='M0,0V213.934H368.961Z' transform='translate(368.961 213.934) rotate(180)' fill='%23006e88'/%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: top right;
                            padding: 40px 40px 0 0;
                            background-size: inherit;
                            @include media-breakpoint-down(lg) {
                                padding: 0;
                            }
                        }
                    }
                }
                &:nth-child(3n+3){
                    .content-side{
                        padding-top: 130px;
                        @include media-breakpoint-down(xl) {
                            padding-top: 0px;
                        }
                        @include media-breakpoint-down(lg) {
                            padding-top: 20px;
                        }
                        &::before{
                            top: 155px;
                            @include media-breakpoint-down(xl) {
                                top: 20px;
                            }
                            @include media-breakpoint-down(lg) {
                                top: 30px;
                            }
                        }
                    }
                    .image-side{
                        .image-wrapper{
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='502' height='291.133' viewBox='0 0 502 291.133'%3E%3Cpath id='Path_281' data-name='Path 281' d='M502,291.133V0H0Z' transform='translate(502 291.133) rotate(180)' fill='%23bd8052'/%3E%3C/svg%3E%0A");                        background-repeat: no-repeat;
                            background-position: bottom left;
                            padding: 0 0 40px 40px;
                            background-size: inherit;
                            @include media-breakpoint-down(lg) {
                                padding: 0;
                            }
                        }
                    }
                }
                .image-side{
                    .image-wrapper{
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='656' height='412.626' viewBox='0 0 656 412.626'%3E%3Cpath id='Path_279' data-name='Path 279' d='M0-42.724V369.9H656Z' transform='translate(0 42.724)' fill='%23eec0b8'/%3E%3C/svg%3E%0A");                    background-position: bottom left;
                        background-repeat: no-repeat;
                        background-position: bottom left;
                        background-size: contain;
                        padding: 0 0 40px 40px;
                        @include media-breakpoint-down(lg) {
                            padding: 0;
                        }
                        img{
                            max-width: 100%;
                            height: 560px;
                            object-fit: cover;
                            object-position: center;
                            width: 100%;
                            @include media-breakpoint-down(xl) {
                                height: 314px;
                            }
                            @include media-breakpoint-down(lg) {
                                height: 214px;
                                
                            }
                        }
                    }
                }
                .content-side{
                    padding-top: 130px;
                    position: relative;
                    @include media-breakpoint-down(xl) {
                        padding-top: 0px;
                    }
                    @include media-breakpoint-down(lg) {
                        padding-top: 20px;
                    }
                    h3{
                        font-size: 24px;
                        font-weight: bold;
                        margin-bottom: 25px;
                        @include media-breakpoint-down(xl) {
                            font-size: 20px;
                        }
                    }
                    .btn{
                        margin-top: 10px;
                    }
                    &::before{
                        content: "";
                        width: 64px;
                        height: 2px;
                        background-color: var(--color-pink);
                        top: 155px;
                        left: -20%;
                        display: block;
                        position: absolute;
                        @include media-breakpoint-down(xl) {
                            top: 20px;
                            left: -20%;
                        }
                        @include media-breakpoint-down(lg) {
                            top: 30px;
                            left: -8%;
                            width: 20px;
                        }
                    }
                }
            }
        }
    }

}