.post-type-archive-developments{
    
    .hero{
        min-height: 675px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xl) {
            min-height: auto;
            .container{
                padding: 0;
            }
        }
        @include media-breakpoint-down(md) {
            .container{
                background: linear-gradient(rgba(92, 112, 124, 0.6), #0C293C);
            }
        }
        h1{
            font-size: 64px;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            max-width: 991px;
            margin: 0 auto 10px;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
                max-width: 100%;
                padding: 0 16px;
                margin: 40px auto 10px;
            }
        }
        p{
            font-size: 20px;
            color: var(--color-white);
            text-align: center;
            max-width: 750px;
            margin: 0 auto 0;
            line-height: 32px;
            @include media-breakpoint-down(xl) {
                font-size: 16px;
                max-width: 100%;
                padding: 0 20px;
            }
        }
        .development-plot-filter{
            margin: 20px auto 0;
            @include media-breakpoint-down(xl) {
                margin: 0px auto;
                padding: 25px 20px 30px;
            }
            p{
                font-family: var(--font-heading);
                font-size: 24px;
                font-weight: bold;
                color: var(--color-white);
                text-align: left;
                margin: 0 0 15px;
                max-width: 100%;
                @include media-breakpoint-down(xl) {
                    text-align: center;
                    margin: 0 0 15px;
                }
            }
            .filters-wrapper{
                border: 2px solid var(--color-bronze);
                padding: 30px;
                @include media-breakpoint-down(xl) {
                    border: none;
                    padding: 0;
                }
                .row{
                    .location{
                        width: 20%;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                    .bedrooms,
                    .price,
                    .property{
                        width: 13%;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                    .search{
                        width: 15%;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                    .all{
                        width: 25%;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                            display: none;
                        }
                        .btn{
                            background-color: var(--color-light-blue);
                            border: 1px solid var(--color-light-blue) !important;
                            &:hover{
                                background-color: var(--color-white);
                                color: var(--color-light-blue);
                                border: 1px solid var(--color-light-blue) !important;
                            }
                        }
                    }
                }
                label{
                    margin-bottom: 15px;
                    color: var(--color-white);
                    font-family: var(--font-heading);
                    font-weight: bold;
                    font-size: 18px;
                    @include media-breakpoint-down(xl) {
                        font-family: var(--font-paragraph);
                        font-weight: 500;
                        margin: 11px 0;
                    }
                }
                select{
                    background: transparent;
                    border: 1px solid var(--color-white);
                    padding: 15px 11px;
                    min-height: 56px;
                    color: var(--color-white);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    background-position: right 15px center;
                    background-repeat: no-repeat;
                    option{
                        color: var(--color-white);
                        background-color: var(--color-dark-blue);
                        border: 1px solid var(--color-white);
                    }
                }
                .btn{
                    min-height: 56px;
                    width: 100%;
                    line-height: 32px;
                    &:hover{
                        color: var(--color-green) !important;
                        svg{
                            path{
                                fill: var(--color-green);
                            }
                        }
                    }
                    &:active{
                        color: var(--color-blue);
                    }
                    &:focus{
                        color: var(--color-white);
                    }
                    &:target{
                        color: var(--color-white);
                    }
                    &:visited{
                        color: var(--color-white);
                    }
                    &:focus-visible{
                        color: var(--color-white);
                    }
                    @include media-breakpoint-down(xxl) {
                        padding: 10px;
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 25px;
                    }
                }
            }
            .buttons-wrapper{
                margin-top: 30px;
                display: flex;
                justify-content: flex-end;
                flex-direction: row;
                align-items: center;
                @include media-breakpoint-down(xl) {
                    flex-direction: column;
                }
                .clear-filter{
                    margin-right: 25px;
                    @include media-breakpoint-down(xl) {
                        margin: 0 0 25px;
                    }
                    .clear-btn-home{
                        box-shadow: none;
                        border: none;
                        background-color: transparent;
                        svg{
                            margin-right: 10px;
                        }
                        font-size: 16px;
                        text-decoration: underline;
                        color: var(--color-white);
                    }
                }
                .view-swap{
                    background-color: var(--color-bronze);
                    min-width: 250px;
                    border: 3px solid var(--color-bronze);
                    display: flex;
                    flex-direction: row;
                    .list-view,
                    .map-view{
                        width: 50%;
                        padding: 10px;
                        font-size: 20px;
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        &:hover{
                            cursor: pointer;
                        }
                        &.active{
                            background-color: var(--color-white);
                            color: var(--color-dark-blue);
                            svg{
                                path{
                                    fill: var(--color-dark-blue);
                                }
                            }
                        }
                        svg{
                           margin-right: 13px; 
                        }
                    }
                }
            }
        }
    }

    .map-container{
        display: none;
        .map{
            
            padding: 100px 0 50px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1258' height='708' viewBox='0 0 1258 708'%3E%3Cpath id='Path_416' data-name='Path 416' d='M0,0V708H1258Z' transform='translate(0)' fill='%23cce2e7'/%3E%3C/svg%3E%0A");
            background-position: bottom left;
            background-repeat: no-repeat;
            @include media-breakpoint-down(xl) {
                padding: 0;
                background: none;
                .container{
                    padding: 0;
                }
            }
            .map-wrapper{
                position: relative;
                .map-key{
                    position: absolute;
                    top: 40px;
                    left: 40px;
                    border: 2px solid var(--color-bronze);
                    background-color: var(--color-white);
                    width: 325px;
                    padding: 25px 30px;
                    .row{
                        margin: 20px 0 0;
                        &:first-child{
                            margin: 0;
                        }
                        svg{
                            max-width: 100%;
                        }
                        p{
                            margin-bottom: 0;
                            font-family: var(--font-heading);
                            font-weight: bold;
                            color: var(--color-dark-blue);
                            font-size: 20px;
                            padding-left: 10px;
                        }
                    }
                }
                .google-verification{
                    position: absolute;
                    width: calc(100% - calc(var(--bs-gutter-x) * .5));
                    bottom: 0;
                    left: calc(var(--bs-gutter-x) * .5);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    a{
                        background-color: var(--color-dark-blue);
                        padding: 20px;
                        color: var(--color-white);
                        text-decoration: underline;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                            padding: 10px;
                        }
                    }
                    img{
                        max-width: 115px;
                    }
                }
                #mapContainer{
                    height: 960px;
                    @include media-breakpoint-down(xl) {
                        height: 684px;
                    }
                    .gm-style-iw{
                        border-radius: 0;
                        background: var(--color-dark-blue);
                        padding: 0;
                        max-width: none !important;
                        min-width: 265px !important;
                        min-height: 245px !important;
                        max-height: none !important;
                        overflow: visible !important;
                        @include media-breakpoint-down(xl) {
                            max-width: 350px !important;
                        }
                        .gm-style-iw-d{
                            overflow: auto !important;
                            .custom-info-window{
                                padding: 35px 40px 40px;
                                @include media-breakpoint-down(xl) {
                                    padding: 0px 0px 20px;
                                }
                                img{
                                    height: 330px;
                                    width: 529px;
                                    object-fit: cover;
                                    margin-bottom: 15px;
                                    @include media-breakpoint-down(xl) {
                                        height: 147px;
                                        width: 100%;
                                    }
                                }
                                h3{
                                    color: var(--color-white);
                                    font-weight: bold;
                                    font-size: 24px;
                                    @include media-breakpoint-down(xl) {
                                        font-size: 20px;
                                        padding: 0 20px;
                                    }
                                }
                                p{
                                    font-size: 20px;
                                    color: var(--color-white);
                                    font-family: var(--font-paragraph);
                                    font-weight: normal;
                                    @include media-breakpoint-down(xl) {
                                        font-size: 16px;
                                        padding: 0 20px;
                                    }
                                }
                                .btn{
                                    color: var(--color-white) !important;
                                    background-color: var(--color-green) !important;
                                    
                                    @include media-breakpoint-down(xl) {
                                        width: 100%;
                                        margin-bottom: 5px;
                                    }
                                    &.btn-developments{
                                        width: 100%;
                                        background-color: var(--color-light-blue) !important;
                                        border: 1px solid var(--color-light-blue) !important;
                                        width: 100%;
                                        &:hover{
                                            border: 1px solid var(--color-light-blue) !important;
                                            color: var(--color-light-blue);
                                            background-color: var(--color-white);
                                        }
                                    }
                                }
                            }
                        }
                        button{
                            &.gm-ui-hover-effect{
                                background: var(--color-dark-blue) !important;
                                top: -15px !important;
                                right: -15px !important;
                                width: 47px !important;
                                height: 47px !important;
                                border-radius: 100px !important;
                                opacity: 1 !important;
                                span{
                                    -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.729' height='23.732' viewBox='0 0 23.729 23.732'%3E%3Cpath id='Path_186' data-name='Path 186' d='M152.22,129.359a1.469,1.469,0,0,0-2.085,0L140.79,138.7l-9.345-9.345a1.475,1.475,0,0,0-2.085,2.085l9.345,9.345-9.345,9.345a1.479,1.479,0,0,0,1.037,2.522,1.438,1.438,0,0,0,1.037-.437l9.345-9.345,9.345,9.345a1.484,1.484,0,0,0,1.037.437,1.421,1.421,0,0,0,1.037-.437,1.47,1.47,0,0,0,0-2.085l-9.324-9.345,9.345-9.345A1.47,1.47,0,0,0,152.22,129.359Z' transform='translate(-128.925 -128.925)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
                                    width: 23px !important;
                                    height: 23px !important;
                                    margin: 0 auto !important;
                                    background-color: var(--color-white) !important;
                                }
                            }
                        }
                    }
                    .gm-style-iw-tc{
                        &::after{
                            background: var(--color-dark-blue);
                        }
                    }
                }
            }
        }
        .plots{
            background-color: var(--color-off-white);
            padding: 65px 0 50px;
            background-image: none;
            @include media-breakpoint-down(xl) {
                padding: 30px 0px;
            }
            h2{
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 25px;
                text-align: center;
                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                }
            }
            .plot{
                padding: 0 12px;
                @include media-breakpoint-down(lg) {
                    padding: 0 0 24px;
                }
                .image-wrapper{
                    position: relative;
                    img{
                        width: 100%;
                        max-width: 100%;
                        height: 310px;
                        object-fit: cover;
                    }
                    .image-label{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 17px 24px;
                        min-width: 307px;
                        background-repeat: no-repeat;
                        p{
                            font-family: var(--font-heading);
                            font-weight: bold;
                            color: var(--color-white);
                            font-size: 16px;
                            margin: 0;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            @include media-breakpoint-down(xl) {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .content-wrapper{
                    background-color: var(--color-dark-blue);
                    padding: 30px 30px 40px;
                    h3{
                        font-size: 32px;
                        font-weight: bold;
                        color: var(--color-white);
                        margin-bottom: 5px;
                        @include media-breakpoint-down(xl) {
                            font-size: 20px;
                        }
                    }
                    h4{
                        font-size: 24px;
                        color: var(--color-white);
                        margin-bottom: 10px;
                        @include media-breakpoint-down(xl) {
                            font-size: 14px;
                            margin-bottom: 25px;
                        }
                    }
                    .price{
                        svg{
                            margin: 0 10px 0 5px !important;
                        }
                    }
                    .plot-information,
                    .price,
                    .size{
                        margin-top: 20px;
                        border-left: 5px solid var(--color-dark-pink);
                        padding: 0 0 0 15px;
                        display: flex;
                        align-items: center;
                        svg{
                            margin-right: 16px;
                        }
                        p{
                            font-size: 20px;
                            color: var(--color-white);
                            margin: 0;
                        }
                        @include media-breakpoint-down(xxl) {
                            p{
                                font-size: 14px;
                            }
                        }
                        @include media-breakpoint-down(xl) {
                            margin-top: 8px;
                            p{
                                font-size: 16px;
                            }
                        }
                    }
                    .size{
                        margin-top: 0;
                    }
                    .button-row{
                        margin: 20px 0 0;
                        align-items: center;
                        @include media-breakpoint-down(xxl) {
                            margin-top: 10px;
                        }
                        @include media-breakpoint-down(xl) {
                            margin-top: 8px;
                        }
                    }
                    .columns{
                        border-left: 5px solid var(--color-dark-pink);
                        padding: 0 0 0 15px;
                        display: flex;
                        align-items: center;
                        
                        svg{
                            margin-right: 16px;
                        }
                        p{
                            font-size: 20px;
                            color: var(--color-white);
                            margin: 0;
                        }
                    }
                    .excerpt{
                        margin: 20px 0 40px;
                        color: var(--color-white);
                    }
                    .btn{
                        padding: 10px 20px;
                        @include media-breakpoint-down(xl) {
                            margin-top: 20px;
                        }
                        &.btn-developments{
                            background-color: var(--color-light-blue);
                            border: 1px solid var(--color-light-blue) !important;
                            width: 100%;
                            &:hover{
                                border: 1px solid var(--color-light-blue) !important;
                                color: var(--color-light-blue);
                                background-color: var(--color-white);
                            }
                        }
                    }
                }
            }
            .button{
                text-align: center;
                @include media-breakpoint-down(lg) {
                    display: none;
                }
                .btn{
                    &.main{
                        max-width: 340px;
                        margin-top: 50px;
                        background-color: var(--color-light-blue);
                        color: var(--color-white);
                        border: 1px solid var(--color-light-blue) !important;
                        &:hover{
                            background-color: var(--color-white);
                            color: var(--color-light-blue);
                        }
        
                    }
                }
            }
        }
    }
    .list{
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1258' height='708' viewBox='0 0 1258 708'%3E%3Cpath id='Path_416' data-name='Path 416' d='M0,0V708H1258Z' transform='translate(0)' fill='%23cce2e7'/%3E%3C/svg%3E%0A");
        background-position: bottom left;
        background-repeat: no-repeat;
        @include media-breakpoint-down(xl) {
            background: none;
        }
        .development-wrapper{
            padding: 40px 0;
            
            .development-information{
                padding: 40px 45px;
                background-color: var(--color-dark-blue);
                max-width: 75%;
                margin: 0 auto;
                align-items: center;
                @media (max-width: 1500px) {
                    max-width: 90%;
                    padding: 25px;
                }
                @include media-breakpoint-down(xl) {
                    padding: 0;
                    max-width: 100%;
                    margin: 0 16px;

                }
                .image-wrapper{
                    position: relative;
                    @include media-breakpoint-down(xl) {
                        height: 100%;
                    }
                    img{
                        width: 100%;
                        max-width: 100%;
                        height: 377px;
                        object-fit: cover;
                        @include media-breakpoint-down(xl) {
                            height: 375px;
                        }
                        @include media-breakpoint-down(md) {
                            height: 220px;
                        }
                    }
                    .image-label{
                        position: absolute;
                        top: 0;
                        left: 0;
                        padding: 17px 24px;
                        min-width: 307px;
                        background-repeat: no-repeat;
                        @include media-breakpoint-down(xl) {
                            width: 224px;
                            min-width: auto;
                            padding: 12px 24px;
                            background-size: contain;
                        }
                        p{
                            font-family: var(--font-heading);
                            font-weight: bold;
                            color: var(--color-white);
                            font-size: 16px;
                            margin: 0;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            @include media-breakpoint-down(xl) {
                                font-size: 10px;
                            }
                        }
                    }
                }
                .content-side{
                    padding-left: 90px;
                    @media (max-width: 1600px) {
                        padding-left: 35px;
                    }
                    @include media-breakpoint-down(xl) {
                        padding: 20px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                    }
                }
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 20px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    @include media-breakpoint-down(xl) {
                        &:last-of-type{
                            margin-top: 15px;
                        }
                    }
                    &.price{
                        svg{
                            margin: 0 10px !important;
                        }
                    }
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                        @include media-breakpoint-down(xl) {
                            font-size: 16px;
                        }
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                    @include media-breakpoint-down(xl) {
                        margin: 20px 0;
                    }
                }
                .btn{
                    padding: 10px 15px;
                    @media (max-width: 1600px) {
                        padding: 10px 18px;
                    }
                    @include media-breakpoint-down(xxl) {
                        padding: 10px 24px;
                    }
                    @include media-breakpoint-down(xl) {
                        width: 100%;
                    }
                    &:hover{
                        color: var(--color-green) !important;
                        svg{
                            path{
                                fill: var(--color-green);
                            }
                        }
                    }
                    &:active{
                        color: var(--color-blue);
                    }
                    &:focus{
                        color: var(--color-white);
                    }
                    &:target{
                        color: var(--color-white);
                    }
                    &:visited{
                        color: var(--color-white);
                    }
                    &:focus-visible{
                        color: var(--color-white);
                    }
                    svg{
                        margin-left: 15px;
                        @include media-breakpoint-down(xxl) {
                            margin-left: 3px;
                        }
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        @include media-breakpoint-down(xl) {
                            margin-top: 10px;
                        }
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
            .plots-section{
                display: none;
                margin: 65px 0 0;
                background-color: var(--color-off-white);
                padding: 80px 230px 65px;
                position: relative;
                @include media-breakpoint-down(xxl) {
                    padding: 30px 25px;
                }
                @include media-breakpoint-down(xl) {
                    padding: 30px 16px;
                    margin: 30px 0px 0;
                }
                &::before{
                    content: "";
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='90.51' height='90.51' viewBox='0 0 90.51 90.51'%3E%3Crect id='Rectangle_448' data-name='Rectangle 448' width='64' height='64' transform='translate(45.255) rotate(45)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    display: block;
                    position: absolute;
                    top: -35px;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 64px;
                    width: 64px;
                    background-size: contain;
                    @include media-breakpoint-down(xl) {
                        width: 26px;
                        height: 26px;
                        top: -12px;
                    }
                }
                .plot{
                    padding: 0 12px;
                    @include media-breakpoint-down(lg) {
                        padding: 0 0 24px;
                    }
                    &:nth-child(n + 4){
                        margin-top: 30px;
                    }
                    .image-wrapper{
                        position: relative;
                        img{
                            width: 100%;
                            max-width: 100%;
                            height: 310px;
                            object-fit: cover;
                        }
                        .image-label{
                            position: absolute;
                            top: 0;
                            left: 0;
                            padding: 17px 24px;
                            min-width: 307px;
                            background-repeat: no-repeat;
                            p{
                                font-family: var(--font-heading);
                                font-weight: bold;
                                color: var(--color-white);
                                font-size: 16px;
                                margin: 0;
                                text-transform: uppercase;
                                letter-spacing: 1px;
                                @include media-breakpoint-down(xl) {
                                    font-size: 10px;
                                }
                            }
                        }
                    }
                    .content-wrapper{
                        background-color: var(--color-dark-blue);
                        padding: 30px 30px 40px;
                        h3{
                            font-size: 32px;
                            font-weight: bold;
                            color: var(--color-white);
                            margin-bottom: 5px;
                            @include media-breakpoint-down(xl) {
                                font-size: 20px;
                            }
                        }
                        h4{
                            font-size: 24px;
                            color: var(--color-white);
                            margin-bottom: 10px;
                            @include media-breakpoint-down(xl) {
                                font-size: 14px;
                                margin-bottom: 25px;
                            }
                        }
                        .plot-information,
                        .price,
                        .size{
                            margin-top: 20px;
                            border-left: 5px solid var(--color-dark-pink);
                            padding: 0 0 0 15px;
                            display: flex;
                            align-items: center;
                            svg{
                                margin-right: 16px;
                            }
                            p{
                                font-size: 20px;
                                color: var(--color-white);
                                margin: 0;
                            }
                            @include media-breakpoint-down(xxl) {
                                p{
                                    font-size: 14px;
                                }
                            }
                            @include media-breakpoint-down(xl) {
                                margin-top: 8px;
                                p{
                                    font-size: 16px;
                                }
                            }
                        }
                        .size{
                            margin-top: 0;
                        }
                        .button-row{
                            margin: 20px 0 0;
                            align-items: center;
                            @include media-breakpoint-down(xxl) {
                                margin-top: 10px;
                            }
                            @include media-breakpoint-down(xl) {
                                margin-top: 8px;
                            }
                        }
                        .columns{
                            border-left: 5px solid var(--color-dark-pink);
                            padding: 0 0 0 15px;
                            display: flex;
                            align-items: center;
                            svg{
                                margin-right: 16px;
                            }
                            p{
                                font-size: 20px;
                                color: var(--color-white);
                                margin: 0;
                            }
                        }
                        .excerpt{
                            margin: 20px 0 40px;
                            color: var(--color-white);
                        }
                        .btn{
                            padding: 10px 20px;
                            @include media-breakpoint-down(xl) {
                                margin-top: 20px;
                            }
                        }
                    }
                }
            }
            .plot-navigation{
                width: 180px;
                height: 80px;
                align-items: center;
                margin: 0 auto;
                position: relative;
                display: none;
                @include media-breakpoint-down(lg) {
                    display: flex;
                }
                .slick-arrow{
                    font-size: 0;
                    top: 45%;
                    transform: translateY(-50%);
                    height: 32px;
                    width: 32px;
                    z-index: 2;
                    &.slick-prev{
                        left: 0;
                        &::before{
                            content: url("data:image/svg+xml,%3Csvg id='Group_735' data-name='Group 735' xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath id='Path_61' data-name='Path 61' d='M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm0,0' transform='translate(40 40) rotate(180)' fill='%230c293c'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M2.135.306A1.324,1.324,0,0,0,.318.423,1.205,1.205,0,0,0,.44,2.169L9.629,9.9.44,17.63a1.2,1.2,0,0,0-.122,1.746,1.323,1.323,0,0,0,1.816.117l10.3-8.662a1.207,1.207,0,0,0,0-1.863Zm0,0' transform='translate(25.391 29.899) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
                            opacity: 1;
                        }
                    }
                    &.slick-next{
                        right: 5px;
                        &::before{
                            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cg id='Group_734' data-name='Group 734' transform='translate(73.794 1998.082) rotate(180)'%3E%3Cpath id='Path_61' data-name='Path 61' d='M20,0A20,20,0,1,0,40,20,20,20,0,0,0,20,0Zm0,38A18,18,0,1,1,38,20,18,18,0,0,1,20,38Zm0,0' transform='translate(73.794 1998.082) rotate(180)' fill='%230c293c'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M2.135.306A1.324,1.324,0,0,0,.318.423,1.205,1.205,0,0,0,.44,2.169L9.629,9.9.44,17.63a1.2,1.2,0,0,0-.122,1.746,1.323,1.323,0,0,0,1.816.117l10.3-8.662a1.207,1.207,0,0,0,0-1.863Zm0,0' transform='translate(59.184 1987.982) rotate(180)' fill='%230c293c'/%3E%3C/g%3E%3C/svg%3E%0A");
                            opacity: 1;
    
                        }
                    }
                }
                .numbers{
                    text-align: center;
                    max-width: 100%;
                    p{
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        color: var(--color-dark-blue);
                        margin: 0;
                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    

}