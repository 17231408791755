// The very top of the site, element that usually includes
// the site navigation and search for example.
.site-header {
  display: block;
  max-width: 100%;
  margin: 0;
  padding: 0px;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999999;
  &.shrink{
    position: fixed;
    background-color: $white;
  }
  .row{
    .col-12{
      width: 100%;
      nav{
        padding: 0px 90px 20px 120px;
        flex-wrap: wrap;
        justify-content: center;
        @include media-breakpoint-down(xxl) {
          padding: 10px 20px;
          justify-content: space-between;
        }
        @include media-breakpoint-down(lg) {
          justify-content: space-between;
        }
        .header-logo{
          @include media-breakpoint-down(xxl) {
            text-align: center;
            margin: 0 0 10px 0;
          }
          @include media-breakpoint-down(lg) {
            text-align: left;
            margin: 0px;
          }
          img{
            max-width: 100%;
            @include media-breakpoint-down(xxl) {
              max-width: 20%;
            }
            @include media-breakpoint-down(lg) {
              max-width: 50%;
            }
            @include media-breakpoint-down(sm) {
              max-width: 70%;
            }
          }
        }
        #mainNavigation1{
          @include media-breakpoint-down(lg) {
            display: none;
          }
          #menu-primary{
            justify-content: space-between;
            align-items: center;
            @include media-breakpoint-down(xxl) {
              justify-content: space-between;
            }
            .menu-item{
              padding: 0 22px;
              @include media-breakpoint-down(xxl) {
                padding: 0 10px;
              }
              &:last-of-type{
                margin: 0;
              }
              &.find-your-home{
                a{
                  color: var(--color-green);
                  padding: 10px 16px;
                  border: 2px solid var(--color-green);
                  display: flex;
                  &::before{
                    content: url("data:image/svg+xml,%3Csvg id='Group_151' data-name='Group 151' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_53' data-name='Rectangle 53' width='24' height='24' fill='%23168f4f'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_150' data-name='Group 150' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_120' data-name='Path 120' d='M23.583,21.572,18.969,16.96A10.56,10.56,0,0,0,10.564,0,10.568,10.568,0,0,0,3.09,18.04a10.549,10.549,0,0,0,13.867.931l4.614,4.613a1.422,1.422,0,1,0,2.012-2.011M5.1,16.029a7.726,7.726,0,1,1,5.462,2.262A7.731,7.731,0,0,1,5.1,16.029' transform='translate(0)' fill='%23168f4f'/%3E%3C/g%3E%3C/svg%3E%0A");
                    display: block;
                    max-height: 24px;
                    margin-right: 15px;
                  }
                  &:hover{
                    background-color: var(--color-green);
                    color: var(--color-white);
                    &::before{
                      content: url("data:image/svg+xml,%3Csvg id='Group_151' data-name='Group 151' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_53' data-name='Rectangle 53' width='24' height='24' fill='%23ffffff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_150' data-name='Group 150' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_120' data-name='Path 120' d='M23.583,21.572,18.969,16.96A10.56,10.56,0,0,0,10.564,0,10.568,10.568,0,0,0,3.09,18.04a10.549,10.549,0,0,0,13.867.931l4.614,4.613a1.422,1.422,0,1,0,2.012-2.011M5.1,16.029a7.726,7.726,0,1,1,5.462,2.262A7.731,7.731,0,0,1,5.1,16.029' transform='translate(0)' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                  }
                }
              }
              &.menu-item-has-children{
                position: relative;
                &::after{
                  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_11' data-name='Polygon 11' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
                  display: block;
                  position: absolute;
                  top: 20px;
                  visibility: visible;
                  left: 50%;
                  width: 9px;
                  transform: translateX(-50%);
                }
                &:hover{
                  &::after{
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_10' data-name='Polygon 10' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23006e88'/%3E%3C/svg%3E%0A");
                  }
                  .sub-menu{
                    opacity: 1;
                    visibility: visible;
                  }
                }
                .sub-menu{
                  position: absolute;
                  top: 0px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: 285px;
                  background-color: var(--color-dark-blue);
                  padding: 0;
                  margin-top: 50px;
                  .menu-item{
                    width: 100%;
                    padding: 0;
                    border-bottom: 1px solid var(--color-grey);
                    a{
                      padding: 15px 30px;
                      background-color: var(--color-dark-blue);
                      font-size: 20px;
                      font-weight: lighter;
                      color: var(--color-white);
                      width: 100%;
                      text-transform: none;
                      font-family: var(--font-paragraph);
                      text-align: left;
                      &:hover{
                        background-color: var(--color-light-blue);
                        color: var(--color-white);
                        font-weight: 500;
                        transition: 0.05s all;
                      }
                    }
                    &:last-of-type{
                      border: none;
                    }
                  }
                  &.hovered{
                    &::before{
                      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='24.096' viewBox='0 0 35 24.096'%3E%3Cpath id='Polygon_9' data-name='Polygon 9' d='M17.5,0,35,24.1H0Z' fill='%23006e88'/%3E%3C/svg%3E%0A");
                    }
                  }
                  &::before{
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='24.096' viewBox='0 0 35 24.096'%3E%3Cpath id='Polygon_9' data-name='Polygon 9' d='M17.5,0,35,24.1H0Z' fill='%230C293C'/%3E%3C/svg%3E%0A");
                    display: block;
                    top: -24px;
                    left: 50%;
                    transform: translateX(-50%);
                    max-height: 24px;
                    position: absolute;
                  }
                }
              }
              a{
                padding: 0;
                font-size: 18px;
                color: var(--color-dark-blue);
                font-weight: bold;
                letter-spacing: 1px;
                text-decoration: none;
                text-transform: uppercase;
                &:hover{
                  color: var(--color-light-blue);
                }
              }
            }
          }
        }
        .mobile-menu{
          text-align: right;
          .search{
            max-width: 414px;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='414.765' height='95.001' viewBox='0 0 414.765 95.001'%3E%3Cpath id='Subtraction_32' data-name='Subtraction 32' d='M254.037,103.733H0l160.726-95H414.764l-160.726,95Z' transform='translate(0.001 -8.733)' fill='%23f8e6e3'/%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-size: cover;
            height: 75px;
            margin-left: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            transition: 0.3s all;
            @include media-breakpoint-down(xxl) {
              margin: 0 auto 30px;
              position: absolute;
              top: 0;
              right: 10px;
              width: 380px;
            }
            @include media-breakpoint-down(lg) {
              display: none;
            }
            &.active{
              background-image: none;
              background-color: #F8E6E3;
              padding: 20px;
              svg{
                display: none;
              }
              p{
                display: none;
              }
              .search-bar{
                display: flex;
                align-items: center;
                svg{
                  display: block;
                }
              }
            }
            svg{
              margin-right: 15px;
              &:hover{
                cursor: pointer;
              }
            }
            p{
              margin: 0;
              font-family: var(--font-lato);
              font-size: 19px;
              color: var(--color-dark-blue);
              font-size: 19px;
              letter-spacing: 1px;
              text-transform: uppercase;
              font-weight: bold;
              &:hover{
                cursor: pointer;
              }
            }
            .search-bar{
              display: none;
              form{
                .search-field{
                  border: none;
                  border-bottom: 2px solid var(--color-dark-blue);
                  font-family: var(--font-lato);
                  font-size: 19px;
                  color: var(--color-dark-blue);
                  font-size: 19px;
                  letter-spacing: 1px;
                  text-transform: uppercase;
                  font-weight: bold;
                  &:focus-visible{
                    outline: none;
                  }
                }
              }
            }
          }
          .navbar-toggler{
            padding: 0;
            height: auto;
            border: none;
            box-shadow: none;
            display: block;
            margin-left: auto;
            @include media-breakpoint-up(lg) {
              display: none;
            }
            &::before{
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='31' height='19' viewBox='0 0 31 19'%3E%3Cg id='Group_745' data-name='Group 745' transform='translate(-267 -37.5)'%3E%3Cline id='Line_100' data-name='Line 100' x2='28' transform='translate(268.5 39)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='3'/%3E%3Cline id='Line_101' data-name='Line 101' x2='28' transform='translate(268.5 47)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='3'/%3E%3Cline id='Line_102' data-name='Line 102' x2='28' transform='translate(268.5 47)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='3'/%3E%3Cline id='Line_103' data-name='Line 103' x2='28' transform='translate(268.5 55)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='3'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
            &.open{
              &::before{
                content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='13.536' height='13.536' viewBox='0 0 13.536 13.536'%3E%3Cg id='Group_774' data-name='Group 774' transform='translate(-273.232 -32.232)'%3E%3Cline id='Line_101' data-name='Line 101' y1='10' x2='10' transform='translate(275 34)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='2.5'/%3E%3Cline id='Line_102' data-name='Line 102' x2='10' y2='10' transform='translate(275 34)' fill='none' stroke='%230c293c' stroke-linecap='round' stroke-width='2.5'/%3E%3C/g%3E%3C/svg%3E%0A");
              }
            }
          }

        }
      }
      #navbarToggleExternalContent{
        height: 0;
        opacity: 0;
        transition: 0.5s all;
        display: none;
        &.show{
          display: block;
          height: 100vh;
          opacity: 1;
          transition: 0.5s all;
          transition-delay: 1s;
        }
        .search{
          background-color: #F8E6E3;
          padding: 25px 15px;
          form{
            width: fit-content;
            margin: 0 auto;
            position: relative;
            .search-field{
              border: none;
              border-bottom: 2px solid var(--color-dark-blue);
              font-family: var(--font-lato);
              font-size: 19px;
              color: var(--color-dark-blue);
              font-size: 19px;
              letter-spacing: 1px;
              text-transform: uppercase;
              font-weight: bold;
              padding-left: 30px;
              &:focus-visible{
                outline: none;
              }
            }
            .search-submit{
              position: absolute;
              top: 50%;
              left: 0;
              transform: translateY(-50%);
            }
          }
        }
        .menu{
          #mainNavigationMobile{
            .mobile-menu{
              list-style: none;
              margin: 0;
              padding-inline-start: 0;
              .menu-item{
                padding: 10px 16px;
                border-top: 1px solid rgba(12, 41, 60, 0.1);
                display: block;
                a{
                  font-size: 18px;
                  font-weight: bold;
                  color: var(--color-dark-blue);
                  text-decoration: none;
                  text-transform: uppercase;
                  padding: 0;
                }
                &:last-of-type{
                  border-bottom: 1px solid rgba(12, 41, 60, 0.1);
                }
                &.menu-item-has-children{
                  display: flex;
                  align-items: flex-start;
                  flex-direction: column;
                  justify-content: flex-start;
                  gap: 0;
                  &::after{
                    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_10' data-name='Polygon 10' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
                    position: absolute;
                    visibility: visible;
                    width: 9px;
                    height: 35px;
                    top: 10px;
                    right: 15px;
                  }
                  .sub-menu{
                    position: relative;
                    height: 0;
                    opacity: 1;
                    visibility: visible;
                    display: none;
                    transition: 0.5s height;
                    transition-delay: 0.3s;
                    margin: 0;
                    .menu-item{
                      padding: 8px 0;
                      border: none;
                      a{
                        font-size: 16px;
                        font-weight: bold;
                        text-transform: none;
                        font-family: var(--font-paragraph);
                      }
                    }
                  }
                  &.open{
                    &::after{
                      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_10' data-name='Polygon 10' d='M4.5,0,9,6H0Z' fill='%230c293c'/%3E%3C/svg%3E%0A");
                    }
                    .sub-menu{
                      display: block;
                      height: 100%;
                      transition: 0.5s height;
                      transition-delay: 0.3s;
                    }
                  }
                }
                &.find-your-home{
                  padding: 20px 16px;
                  border: none;
                  a{
                    color: var(--color-green);
                    padding: 10px 16px;
                    border: 2px solid var(--color-green);
                    display: flex;
                    justify-content: center;
                    font-size: 16px;
                    font-weight: bold;
                    letter-spacing: 1px;
                    text-transform: uppercase;
                    text-decoration: none;
                    &::before{
                      content: url("data:image/svg+xml,%3Csvg id='Group_151' data-name='Group 151' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_53' data-name='Rectangle 53' width='24' height='24' fill='%23168f4f'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_150' data-name='Group 150' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_120' data-name='Path 120' d='M23.583,21.572,18.969,16.96A10.56,10.56,0,0,0,10.564,0,10.568,10.568,0,0,0,3.09,18.04a10.549,10.549,0,0,0,13.867.931l4.614,4.613a1.422,1.422,0,1,0,2.012-2.011M5.1,16.029a7.726,7.726,0,1,1,5.462,2.262A7.731,7.731,0,0,1,5.1,16.029' transform='translate(0)' fill='%23168f4f'/%3E%3C/g%3E%3C/svg%3E%0A");
                      display: block;
                      max-height: 24px;
                      margin-right: 15px;
                    }
                    &:hover{
                      background-color: var(--color-green);
                      color: var(--color-white);
                      &::before{
                        content: url("data:image/svg+xml,%3Csvg id='Group_151' data-name='Group 151' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'%3E%3Cdefs%3E%3CclipPath id='clip-path'%3E%3Crect id='Rectangle_53' data-name='Rectangle 53' width='24' height='24' fill='%23ffffff'/%3E%3C/clipPath%3E%3C/defs%3E%3Cg id='Group_150' data-name='Group 150' clip-path='url(%23clip-path)'%3E%3Cpath id='Path_120' data-name='Path 120' d='M23.583,21.572,18.969,16.96A10.56,10.56,0,0,0,10.564,0,10.568,10.568,0,0,0,3.09,18.04a10.549,10.549,0,0,0,13.867.931l4.614,4.613a1.422,1.422,0,1,0,2.012-2.011M5.1,16.029a7.726,7.726,0,1,1,5.462,2.262A7.731,7.731,0,0,1,5.1,16.029' transform='translate(0)' fill='%23ffffff'/%3E%3C/g%3E%3C/svg%3E%0A");
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



