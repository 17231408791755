@use 'sass:math';

// stylelint-disable declaration-property-unit-allowed-list, max-line-length
// Helper mixin for self-hosted fonts
@mixin font($family, $src, $weight: normal, $style: normal) {
  @font-face {
    // @link https://css-tricks.com/almanac/properties/f/font-display/
    font-display: auto;
    font-family: $family;
    font-style: $style;
    font-weight: $weight;

    // Slightly Deeper Browser Support
    // @source https://css-tricks.com/snippets/css/using-font-face/#slightly-deeper-browser-support
    src: url('#{$src}.woff') format('woff'), url('#{$src}.woff2') format('woff2'), url('#{$src}.ttf') format('truetype');
  }
}
