.single-plots{
    .development-nav-bar{
        .nav-bar-wrap{
            border-top: 2px solid var(--color-bronze);
            border-bottom: 2px solid var(--color-bronze);
            li{
                &.active{
                    &::before{
                        background-color: var(--color-bronze);
                    }
                }
            }
            select{
                background-color: var(--color-bronze);
            }
        }
    }
    .hero{
        background-color: var(--color-dark-blue);
        .content-side{
            padding: 0 100px 0 240px;
            @include media-breakpoint-down(xxxl) {
                padding: 0px 100px 0px; 
            }
            @include media-breakpoint-down(xxl) {
                padding: 0px 30px 0px; 
            }
            @include media-breakpoint-down(xl) {
                padding: 0px 40px 0px; 
            }
            @include media-breakpoint-down(lg) {
                order: 2;
                padding: 30px 16px 20px; 
            }
            h1{
                font-size: 32px;
                font-weight: bold;
                color: var(--color-white);
                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                    text-align: center;
                    margin-bottom: 10px;
                }
            }
            h2{
                font-size: 24px;
                color: var(--color-white);
                margin-bottom: 30px;
                @include media-breakpoint-down(xl) {
                    font-size: 18px;
                    text-align: center;
                    margin-bottom: 30px;
                }
            }
            .plot-information,
            .price,
            .size{
                margin-bottom: 20px;
                border-left: 5px solid var(--color-dark-pink);
                padding: 0 0 0 20px;
                display: flex;
                align-items: center;
                min-height: 40px;
                @include media-breakpoint-down(xl) {
                    padding: 0 0 0 10px;
                }
                svg{
                    margin-right: 20px;
                    @include media-breakpoint-down(xl) {
                        margin-right: 10px;
                    }
                }
                p{
                    font-size: 32px;
                    color: var(--color-white);
                    margin: 0;
                }
                @include media-breakpoint-down(xl) {
                    p{
                        font-size: 20px;
                    }
                }
            }
            .btn{
                margin-top: 40px;
                @include media-breakpoint-down(xl) {
                    width: 100%;
                    margin-top: 30px;
                    margin-bottom:30px;
                }
            }
        }
        .slider-side{
            position: relative;
            @include media-breakpoint-down(lg) {
                order: 1;
            }
            .hero-slider{
                .slick-slide{
                    img{
                        height: 705px;
                        object-fit: cover;
                        width: 100%;
                        @include media-breakpoint-down(xl) {
                            height: 400px;
                        }
                    }
                }
            }
            .home-navigation{
                position: absolute;
                bottom: 0px;
                left: 0%;
                width: 375px;
                height: 80px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='375.307' height='80.952' viewBox='0 0 375.307 80.952'%3E%3Cpath id='Path_98' data-name='Path 98' d='M0,80.952H375.307L239.514,0H0Z' fill='%230c293c'/%3E%3C/svg%3E%0A");
                background-size: contain;
                background-repeat: no-repeat;
                display: flex;
                align-items: flex-end;
                @include media-breakpoint-down(xl) {
                    height: 55px;
                    width: 300px;
                }
                .slick-arrow{
                    font-size: 0;
                    top: 60%;
                    transform: translateY(-50%);
                    height: 32px;
                    width: 32px;
                    z-index: 2;
                    @include media-breakpoint-down(xl) {
                        top: 40%;
                    }
                    &.slick-prev{
                        left: 55px;
                        @include media-breakpoint-down(xl) {
                            left: 25px;
                        }
                        &::before{
                            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg id='arrow-right' transform='translate(32 32) rotate(180)'%3E%3Cpath id='Path_61' data-name='Path 61' d='M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30.4A14.4,14.4,0,1,1,30.4,16,14.4,14.4,0,0,1,16,30.4Zm0,0' fill='%23fff'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M1.279.191A.771.771,0,1,0,.264,1.352L5.771,6.17.264,10.989A.771.771,0,1,0,1.279,12.15l6.17-5.4a.772.772,0,0,0,0-1.161Zm0,0' transform='translate(12.953 9.83)' fill='%23fff'/%3E%3C/g%3E%3C/svg%3E%0A");
                            opacity: 1;
                        }
                    }
                    &.slick-next{
                        right: 190px;
                        @include media-breakpoint-down(xl) {
                            right: 145px;
                        }
                        &::before{
                            content: url("data:image/svg+xml,%3Csvg id='arrow-right' xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cpath id='Path_61' data-name='Path 61' d='M16,0A16,16,0,1,0,32,16,16,16,0,0,0,16,0Zm0,30.4A14.4,14.4,0,1,1,30.4,16,14.4,14.4,0,0,1,16,30.4Zm0,0' fill='%23fff'/%3E%3Cpath id='Path_62' data-name='Path 62' d='M171.941,128.19a.771.771,0,1,0-1.015,1.161l5.507,4.818-5.507,4.818a.771.771,0,0,0,1.015,1.161l6.17-5.4a.771.771,0,0,0,0-1.161Zm0,0' transform='translate(-157.709 -118.17)' fill='%23fff'/%3E%3C/svg%3E%0A");
                            opacity: 1;
        
                        }
                    }
                }
                .numbers{
                    text-align: center;
                    max-width: 65%;
                    @include media-breakpoint-down(xl) {
                        max-width: 60%;
                    }
                    p{
                        font-size: 24px;
                        font-weight: 500;
                        letter-spacing: 1px;
                        color: var(--color-white);
                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }

    .introduction{
        padding: 110px 0 40px;
        @include media-breakpoint-down(xl) {
            padding: 30px 0 30px;
        }
        p{
            font-size: 24px;
            margin: 0;
            @include media-breakpoint-down(xl) {
                font-size: 18px;
            }
        }
        
    }

    .seperators{
        margin: 50px 0px 0;
        border-top: 4px solid var(--color-light-blue);
        opacity: 1;
        @include media-breakpoint-down(xl) {
            display: block;
            margin: 30px 0px 0;
        }
    }

    .features{
        margin: 100px 0 80px;
        @include media-breakpoint-down(xl) {
            margin: 0px;
            .container{
                padding: 0;
            }
        }
        .content-side{
            @include media-breakpoint-down(xl) {
                padding: 0 15px;
            }
            h2{
                font-size: 48px;
                font-weight: bold;
                color: var(--color-dark-blue);
                margin-bottom: 10px;
                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                    margin-top:30px;
                }
            }
            p{
               color: var(--color-dark-blue);
               font-size: 16px; 
            }
            .features-wrapper{
                margin: 40px 0 0;
                @include media-breakpoint-down(xl) {
                    margin: 0 0 30px;
                }
                .feature{
                    display: flex;
                    align-items: center;
                    margin-top: 20px;
                    padding: 0;
                    @include media-breakpoint-down(xl) {
                        margin: 12px 0 0;
                    }
                    p{
                        font-size: 16px;
                        font-weight: bold;
                        font-family: var(--font-heading);
                        color: var(--color-dark-blue);
                        margin: 0 0 0 10px;
                    }
                }
            }
            .arrow{
                margin-top: 100px;
                @include media-breakpoint-down(xl) {
                    margin-top: 30px;
                    text-align: center;
                }
            }
        }
        .info-side{
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='545' height='313' viewBox='0 0 545 313'%3E%3Cpath id='Path_457' data-name='Path 457' d='M565,3669.754v313H20Z' transform='translate(-20 -3669.754)' fill='%23f1cdc6'/%3E%3C/svg%3E%0A");
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0;
            .information-box{
                margin: 0 40px 40px 0;
                padding: 35px 25px;
                border: 2px solid var(--color-bronze);
                background-color: var(--color-white);
                @include media-breakpoint-down(xl) {
                    margin: 40px 16px 20px;
                    padding: 20px 16px;
                }
                h2{
                    font-size: 32px;
                    color: var(--color-dark-blue);
                    font-weight: bold;
                    margin-bottom: 15px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                        text-align: center;
                    }
                }
                .information-row{
                    border-top: 2px solid var(--color-bronze);
                    padding: 30px 0;
                    align-items: center;
                    @include media-breakpoint-down(xl) {
                        padding: 15px 0;
                    }
                    @include media-breakpoint-down(lg) {
                        padding: 20px 0;
                        &.mobile{
                            padding: 20px 0 10px;
                            &:last-of-type{
                                border: none;
                                padding: 0;
                            }
                            .col-12{
                                padding: 0;
                                .btn{
                                    padding: 15px 34px;
                                }
                            }
                        }
                    }
                    p{
                        font-size: 24px;
                        color: var(--color-dark-blue);
                        margin: 0;
                        @include media-breakpoint-down(xxl) {
                            font-size: 20px;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 16px;
                        }
                        a{
                            color: var(--color-dark-blue);
                            font-weight: 500;
                        }
                    }
                    .btn{
                        width: 300px;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    .floorplans{
        @include media-breakpoint-down(xl) {
            padding-top: 25px;
            .container{
                padding: 0;
            }
        }
        .floorplans-content{
            h2{
                font-size: 24px;
                font-family: var(--font-heading);
                color: var(--color-dark-blue);
                font-weight: bold;
                margin-bottom: 25px;
                text-align: center;
            }
            .nav-tabs{
                justify-content: flex-end;
                padding-right: 70px;
                border-bottom: 4px solid var(--color-light-blue);
                position: relative;
                @include media-breakpoint-down(xl) {
                    padding: 0;
                    justify-content: center;
                }
                &::before{
                    content: "Floor plans";
                    font-size: 32px;
                    font-family: var(--font-heading);
                    color: var(--color-dark-blue);
                    font-weight: bold;
                    position: absolute;
                    left: 0;
                    @include media-breakpoint-down(xl) {
                        display: none;
                    }
                }
                .nav-item{
                    @include media-breakpoint-down(xl) {
                        width: 45%;
                    }
                    .nav-link{
                        border: 4px solid var(--color-light-blue);
                        border-bottom: 0;
                        margin-bottom: -4px;
                        padding: 20px 45px;
                        border-radius: 0;
                        font-family: var(--font-heading);
                        font-weight: bold;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        background-color: var(--color-light-blue);
                        color: var(--color-white);
                        width: 100%;
                        @include media-breakpoint-down(xl) {
                            padding: 11px;
                            font-size: 14px;
                        }
                        &.active{
                            background-color: var(--color-white);
                            color: var(--color-dark-blue);
                        }
                    }
                }
            }
            .tab-content{
                .tab-pane{
                    padding: 60px 0 35px;
                    @include media-breakpoint-down(xl) {
                        padding: 20px 0 0px;
                    }
                    img{
                        max-width: 100%;
                        width: 100%;
                    }
                    .rooms-table{
                        .caveat{
                            font-size: 14px;
                            margin-top: 20px;
                            @include media-breakpoint-down(xl) {
                                font-size: 12px;
                            }
                        }
                        .rooms{
                            padding: 20px 0;
                            border-top: 2px solid #D0E1E6;
                            @include media-breakpoint-down(xl) {
                                padding: 10px 0;
                            }
                            &:last-of-type{
                                border-bottom: 2px solid #D0E1E6;
                            }
                            p{
                                font-family: var(--font-heading);
                                font-size: 18px;
                                margin: 0;
                                color: var(--color-dark-blue);
                                @include media-breakpoint-down(xl) {
                                    font-size: 14px;
                                    text-align: right;
                                }
                                &.room-title{
                                    font-size: 24px;
                                    font-weight: bold;
                                    @include media-breakpoint-down(xl) {
                                        font-size: 16px;
                                        text-align: left;
                                    }
                                }
                            } 
                        }
                    }
                    .buttons{
                        display: flex;
                        justify-content: space-between;
                        @include media-breakpoint-down(xl) {
                            flex-direction: column;
                        }
                        .button{
                            .btn{
                                @include media-breakpoint-down(xl) {
                                    width: 100%;
                                }
                            }
                            &:last-of-type{
                                .btn{
                                    background-color: var(--color-light-blue);
                                    border: 1px solid var(--color-light-blue) !important;
                                    @include media-breakpoint-down(xl) {
                                        margin-top: 10px;
                                    }
                                    &:hover{
                                        border: 1px solid var(--color-light-blue) !important;
                                        color: var(--color-light-blue);
                                        background-color: var(--color-white);
                                    }

                                }
                            }
                        }
                    }
                    hr{
                        margin: 30px 0px;
                        border-top: 4px solid var(--color-light-blue);
                        opacity: 1;
                        @include media-breakpoint-down(xl) {
                            display: block;
                            margin: 30px 0px;
                            border-top: 2px solid var(--color-pink);
                        }
                    }
                    .helpful-information{
                        h1, h2, h3, h4, h5, h6{
                            font-weight: bold;
                            color: var(--color-dark-blue);
                            margin-bottom: 20px;
                            @include media-breakpoint-down(xl) {
                                font-size: 16px;
                                margin-bottom: 10px;
                            }
                        }
                        p{
                           font-size: 20px;
                           font-weight: light;
                           color: var(--color-dark-blue);
                           @include media-breakpoint-down(xl) {
                            font-size: 16px;
                            }
                        }
                    }
                    .more-information{
                        margin-top: 30px;
                        @include media-breakpoint-down(xl) {
                            margin-top: 20px;
                        }
                        p{
                            font-size: 18px;
                            margin: 0;
                            span{
                                color: var(--color-light-blue);
                            }
                        }
                    }
                    .more-button{
                        margin-top: 10px;
                        @include media-breakpoint-down(xl) {
                            margin-top: 20px;
                        }
                        a{
                            font-size: 16px;
                            color: var(--color-green);
                            text-decoration-color: var(--color-green);
                            text-underline-offset: 1px;
                        }
                    }
                }
            }
        }
    }

    .virtual-tour{
        background: rgb(255,255,255);
        background: linear-gradient(-29deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 50%, rgba(204,226,231,1) 50%, rgba(204,226,231,1) 100%);
        padding: 80px 0px;
        @include media-breakpoint-down(xl) {
            padding: 35px 0px 45px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            color: var(--color-dark-blue);
            margin-bottom: 20px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }
        }
        iframe{
            min-height: 800px;
            @include media-breakpoint-down(xl) {
                min-height: 550px;
            }
            @include media-breakpoint-down(lg) {
                min-height: 350px;
            }
        }
    }

    .plots{
        background-color: var(--color-off-white);
        padding: 65px 0 50px;
        background-image: none;
        @include media-breakpoint-down(xl) {
            padding: 30px 0px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 25px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }
        }
        .plot{
            padding: 0 12px;
            @include media-breakpoint-down(lg) {
                padding: 0 0 24px;
            }
            .image-wrapper{
                position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    height: 310px;
                    object-fit: cover;
                }
                .image-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 17px 24px 17px 13px;
                    min-width: 307px;
                    background-repeat: no-repeat;
                    p{
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 16px;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .content-wrapper{
                background-color: var(--color-dark-blue);
                padding: 30px 30px 40px;
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 5px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                h4{
                    font-size: 24px;
                    color: var(--color-white);
                    margin-bottom: 10px;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        margin-bottom: 25px;
                    }
                }
                .plot-information,
                .price,
                .size{
                    margin-top: 20px;
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                    @include media-breakpoint-down(xxl) {
                        p{
                            font-size: 14px;
                        }
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                        p{
                            font-size: 16px;
                        }
                    }
                }
                .size{
                    margin-top: 0;
                }
                .button-row{
                    margin: 20px 0 0;
                    align-items: center;
                    @include media-breakpoint-down(xxl) {
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                }
                .btn{
                    padding: 10px 20px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
        }
        .button{
            text-align: center;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .btn{
                &.main{
                    max-width: 340px;
                    margin-top: 50px;
                    background-color: var(--color-light-blue);
                    color: var(--color-white);
                    border: 1px solid var(--color-light-blue) !important;
                    &:hover{
                        background-color: var(--color-white);
                        color: var(--color-light-blue);
                    }
    
                }
            }
        }
    }

}
