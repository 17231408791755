// Layout widths
$width-max-layout: 100%;
$width-max-article: 800px;
$width-grid-base: 1440px;

// This is the breakpoint navigation transforms into mobile menu
// NB! IMPORTANT! Remember to change in js/src/navigation.js:
// const responsivenav = 960;
// If these are not the same, navigation toggles will not work properly.
$width-max-mobile: 960px;

// Breakpoints for containers
$container-desktop: 1200px;
$container-ipad: 770px;
$container-ipad-landscape: 1024px;
$container-macbook-air: 1440px;
$container-mobile: 550px;
$container-tablet: 768px;

// Gutenberg widths
$width-wide: 1200px;
$width-full: 100%;

// device widths
$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;
