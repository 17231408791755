.brochure{
    background: var(--color-white);
    padding: 65px 142px;
    @include media-breakpoint-down(xxl) {
        padding: 30px 50px;
    }
    @include media-breakpoint-down(lg) {
        padding: 0;
    }
    .brochure-wrapper{
        background-color: var(--color-dark-blue);
        background-image: url("data:image/svg+xml,%3Csvg id='Component_20_1' data-name='Component 20 – 1' xmlns='http://www.w3.org/2000/svg' width='1636' height='608.652' viewBox='0 0 1636 608.652'%3E%3Cpath id='Path_226' data-name='Path 226' d='M0,0V608.652H1080Z' transform='translate(0)' fill='%23006e88'/%3E%3Cpath id='Path_227' data-name='Path 227' d='M760,0V428.546H0Z' transform='translate(876 180.105)' fill='%23eec0b8'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: bottom;
        padding: 80px 100px;
        @include media-breakpoint-down(xxl) {
            padding: 30px 50px;
        }
        @include media-breakpoint-down(lg) {
            padding: 16px;
            background-size: contain;
        }
        .image-side{
            padding: 0 24px 0 0;
            @include media-breakpoint-down(lg) {
                padding: 0 0 15px 0;
            }
            img{
                max-width: 100%;
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include media-breakpoint-down(lg) {
                    max-height: 387px;
                }
            }
        }
        .content-side{
            background-color: var(--color-white);
            padding: 40px 45px;
            @include media-breakpoint-down(xl) {
                padding: 25px 20px;
                
            }
            h2{
                font-size: 32px;
                font-weight: bold;
                margin-bottom: 20px;
                @include media-breakpoint-down(xl) {
                    font-size: 24px;
                    margin-bottom: 15px;
                    text-align: center;
                }
            }
            p{
                font-size: 16px;
                color: var(--color-light-grey);
                @include media-breakpoint-down(xl) {
                    text-align: center;
                }
            }
        }
    }
}