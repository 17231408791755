.gform_wrapper{
  .gfield_validation_message{
    font-family: var(--font-paragraph) !important;
    font-size: 16px  !important;
    font-weight: 500  !important;
    margin: 0  !important;
  }
  .gform_validation_errors{
      .gform_submission_error{
          font-family: var(--font-paragraph);
          font-size: 16px  !important;
          font-weight: 500  !important;
          margin: 0  !important;
      }
  }
  form{
    .gform-body,
    .gform_body{
      .gform_fields{
        .gfield{
          .gfield_label,
          .gform-field-label{
            font-size: 16px;
            font-family: var(--font-paragraph);
            font-weight: 500;
            color: var(--color-dark-blue);
            margin: 0 0 15px;
            span{
              display: none;
            }
          }
          .ginput_container{
            input,
            select,
            textarea{
              background-color: transparent;
              border-radius: 0;
              border: 1px solid var(--color-dark-blue);
              padding: 10px 8px;
              &:focus{
                outline: 0;
              }
              &:focus-visible{
                outline: 0;
              }
            }
            .gfield_checkbox{
              
              .gchoice{
                display: flex;
                align-items: center;
                
                input{
                  padding: 0;
                  width: 16px;
                  height: 16px;
                  &:checked{
                    &::before{
                      border: 1px solid var(--color-dark-blue);
                      color: var(--color-dark-blue);
                      background-color: var(--color-dark-blue);
                      width: 15px;
                      height: 15px;
                      background-image: none;
                      display: block;
                    }
                  }
                }
                label{
                  margin: 0;
                  margin-left: 10px;
                  min-width: 80px;
                }
              }
            }
            &.ginput_container_consent{
              display: flex;
              align-items: center;
              input{
                padding: 0;
                width: 16px;
                height: 16px;
                &:checked{
                  &::before{
                    background-color: var(--color-dark-blue);
                    width: 16px;
                    height: 16px;
                    background-image: none;
                  }
                }
              }
              label{
                margin: 0;
                margin-left: 10px;
              }
            }
          }
        }
      }
    }
    .gform_footer{
      .gform_button{
        background-color: var(--color-green);
        color: var(--color-white);
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 16px 34px;
        border-radius: 0;
        border: 1px solid var(--color-green) !important;
        font-family: var(--font-button);
        &:hover{
            background-color: var(--color-white);
            color: var(--color-green);
        }
      }
    }
  }
}
