// stylelint-disable max-line-length
// Font family settings
// You can define your own font families here, like --font-barlow
// and then attach them to elements: --font-paragraph: var(--font-barlow)

:root {
  // Fonts in use for content
  --font-lato: "Lato", sans-serif;
  --font-larsseit: "Larsseit", sans-serif;
  --font-santral: "Santral", sans-serif;
  --font-georgia: 'Georgia', sans-serif;
  --font-lora: "Lora", serif;
  --font-georgia-paragraph: var(--font-georgia);
  --font-lato-paragraph: var(--font-lato);
  --font-heading: var(--font-santral);
  --font-paragraph: var(--font-larsseit);
  --font-button: var(--font-lato);
}

h1,
h2,
h3,
h4,
h5,
h6{
  font-family: var(--font-heading);
  color: var(--color-dark-blue);
}

p{
  font-family: var(--font-paragraph);
  color: var(--color-light-grey);
  line-height: 24px;
  strong{
    font-family: var(--font-heading);
  }
}

ul{
  padding-inline-start: 15px;
  li{
    color: var(--color-light-grey);
    font-family: var(--font-paragraph);
  }
}


