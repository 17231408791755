// The very bottom of the site. Usually contains supporting
// or secondary navigation, social media icons, contact details
// and such.

// Please note: These are mostly for demo purposes
// so feel free to remove everything in this file
// and start over.
.site-footer {
  max-height: 100%;
  background-color: var(--color-dark-blue);
  background-image: url('../../public/images/footer_background.png');
  background-position: bottom;
  background-repeat: no-repeat;
  overflow: visible;
  background-size: contain;
  @include media-breakpoint-down(xxxl) {
    padding: 0px 100px 110px;
  }
  @include media-breakpoint-down(xxl) {
    padding: 0px 10px 100px;
  }
  @include media-breakpoint-down(xl) {
    padding: 0px 15px 25px;
  }
  .logo-overlay{
    position: relative;
    top: -25px;
    margin-bottom: 20px;
    text-align: center;
    @include media-breakpoint-down(xl) {
      margin-bottom: 0px;
    }
    h2{
      margin: 25px 0 15px;
      color: var(--color-white);
      font-size: 32px;
      font-weight: bold;
      text-align: center;
      @include media-breakpoint-down(xl) {
        font-size: 22px;
      }
    }
    p{
      font-size: 28px;
      font-style: italic;
      color: var(--color-white);
      margin: 0;
      font-family: var(--font-lora);
      letter-spacing: -2px;
      @include media-breakpoint-down(xl) {
        font-size: 20px;
      }
    }
  }
  .socials{
    padding-left: 17px;
    @include media-breakpoint-down(xl) {
      justify-content: center;
      margin-bottom: 20px;
    }
  }
  .logos{
    margin: 50px 0px 0px;
    .logo{
      margin-bottom: 10px;
      img{
        max-width: 100%;
        @include media-breakpoint-down(lg) {
          max-width: 150px;
        }
      }
    }
    svg{
      max-width: 100%;
    }
    
    @include media-breakpoint-down(xl) {
      margin-top: 20px;
      justify-content: center;
      text-align: center;
    }
  }
  .group-section{
    margin-top: 60px;
    text-align: left;
    .group{
      svg{
        max-width: 100%;
        max-height: 100%;
        @include media-breakpoint-down(xl) {
          svg{
            max-width: 245px;
            margin: 0;
          }
        }
      }
    }
    svg{
      margin-bottom: 15px;
      max-width: 35px;
      max-height: 35px;
    }
    @include media-breakpoint-down(xl) {
      margin-top: 31px;
      text-align: center;
      svg{
        max-width: 35px;
        max-height: 35px;
        margin: 0;
      }
    }
  }
  .focus-wrapper{
    margin-top: 95px;
    @include media-breakpoint-down(xl) {
      margin-top: 30px;
      h3{
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--color-white);
        font-size: 10px;
      }
    }
    svg{
      max-width: 103px;
      margin-left: 10px;
      @include media-breakpoint-down(xl) {
        margin: 0;
        max-width: 60px;
      }
    }
  }
  .mobile-rows{
    .mobile-drop{
      @include media-breakpoint-down(xl) {
        height: 0;
        opacity: 0;
      }
    }
    h3{
      &.heading{
        font-size: 24px;
        font-weight: bold;
        color: var(--color-white);
        padding-left: 12px;
        border-left: 5px solid var(--color-dark-pink);
        text-align: left;
        line-height: 24px;
        margin-bottom: 20px;
        @include media-breakpoint-down(xl) {
          position: relative;
          font-size: 14px;

          &::after{
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_14' data-name='Polygon 14' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23fff'/%3E%3C/svg%3E%0A");
            position: absolute;
            visibility: visible;
            width: 9px;
            height: 35px;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
    }
    #footerNavigation{
      #menu-footer{
        padding-inline-start: 0px;
        margin: 0;
        list-style: none;
        .menu-item{
          text-align: left;
          padding-left: 17px;
          margin-bottom: 20px;
          @include media-breakpoint-down(xl) {
            margin-bottom: 10px;
          }
          a{
            font-size: 16px;
            text-decoration: none;
            color: var(--color-white);
            padding: 0;
            font-family: var(--font-paragraph);
            font-weight: normal;
            @include media-breakpoint-down(xl) {
              text-decoration: underline;
              font-size: 12px;
            }
          }
          &:hover{
            &::before{
              content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='6' height='9' viewBox='0 0 6 9'%3E%3Cpath id='Polygon_19' data-name='Polygon 19' d='M4.5,0,9,6H0Z' transform='translate(6) rotate(90)' fill='%23fff'/%3E%3C/svg%3E%0A");
              margin-right: 10px;
            }
          }
        }
      }
    }
    
    .cr{
      text-align: left;
      margin: 0;
      padding-left: 17px;
      font-size: 16px;
      text-decoration: none;
      color: var(--color-white);
      font-family: var(--font-paragraph);
      font-weight: normal;
      @include media-breakpoint-down(xl) {
        margin-top: 15px;
        font-size: 12px;
      }
    }
    
    
    &.contact-wrapper,
    &.careers-wrapper{
      text-align: left;
      a{
        font-size: 16px;
        color: var(--color-white);
        text-decoration: underline;
      }
      p{
        font-size: 16px;
        color: var(--color-white);
        margin-top: 10px;
      }
      .mobile-drop{
        padding-left: 17px;
      }
    }
    &.awards-wrapper{
      margin-top: 60px;
      @include media-breakpoint-down(xl) {
        margin-top: 0;
      }
      .awards{
        img{
          max-width: 100%;
        }
      }
      .mobile-drop{
        padding-left: 17px;
      }
    }
    
    @include media-breakpoint-down(xl) {
      &.open{
        h3{
          &::after{
            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_13' data-name='Polygon 13' d='M4.5,0,9,6H0Z' fill='%23fff'/%3E%3C/svg%3E%0A");
          }
        }
        .mobile-drop{
          height: 100%;
          opacity: 1;
          padding: 0px 0px 20px;
          transition: 0.5s all;
        }
      }
    }
  }
}
