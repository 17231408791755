// This file is meant for general styles and containers
// that apply everywhere throughout the website. Add more
// always repeating elements right below.


// Block padding exceptions
// This class is useful when we have multiple blocks stacked with same background-color
// stylelint-disable-next-line selector-max-class, selector-max-specificity
.block.has-unified-padding-if-stacked + .block.has-unified-padding-if-stacked .container {
  overflow: visible;
  padding-top: 0;
}

// Default shade on top of blocks
.shade {
  background-color: var(--color-background-shade);
  height: 100%;
  left: 0;
  opacity: .35;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 0;
}

// Global link helper
// This is useful in services -type of areas where the whole block is a link
// Remember to define aria-label or screen-reader-text for accessibility
.global-link {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 3;
}

// Site wrapper
.site {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  overflow: hidden;
  position: relative;
}

// Containers
.container {
  margin: 0 auto;
  margin-left: auto;
  margin-right: auto;
  max-width: $width-grid-base;
  
  // If base grid is larger than Macbook Air screen
  @if $width-grid-base > $container-macbook-air {
    @media (min-width: $container-ipad-landscape) and (max-width: $container-macbook-air + 6.4rem) {
      padding-left: var(--padding-container-horizontal-large);
      padding-right: var(--padding-container-horizontal-large);
    }
  }
}

// Clear block containers by default
.site-main .container {
  clear: both;
  overflow: hidden;
}

// Meta actions
.entry-footer {
  clear: both;
  display: flex;
  justify-content: space-between;
  margin-top: var(--padding-container-vertical);
  overflow: hidden;
}

// Better edit link
.edit-link {
  clear: both;
  display: block;
  margin-bottom: 0;
  margin-top: 2rem;
  max-width: 100%;
  min-width: 0;
  overflow: hidden;
  text-align: left;
  transition: all $transition-duration;
  width: 100%;

  a {
    background-color: var(--color-white);
    border: 2px dashed var(--color-link-edit-border);
    clear: both;
    color: var(--color-link-edit-text);
    display: block;
    margin: 0;
    padding: 2rem;
    text-align: center;
    text-decoration: none;
    transition: all $transition-duration;
  }

  &:focus,
  a:hover {
    background-color: var(--color-link-edit-background-hover);
    color: var(--color-link-edit-text-hover);
  }
}

// Hide/show helpers
// stylelint-disable-next-line a11y/no-display-none
.hide-on-desktop {
  @media (min-width: $container-ipad-landscape) {
    // Forced to use important here, because makes no sense to write too specific selectors here
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }
}

// Forced to use important here, because makes no sense to write too specific selectors here
// stylelint-disable-next-line a11y/no-display-none
.hide-on-mobile {
  @media (max-width: $container-mobile) {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }
}

// Only function of these is to force hide content based on the view
// Forced to use important here, because makes no sense to write too specific selectors here
// stylelint-disable-next-line a11y/no-display-none
.hide-on-mobile-nav-view {
  @media (max-width: $width-max-mobile) {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }
}

// Forced to use important here, because makes no sense to write too specific selectors here
// stylelint-disable-next-line a11y/no-display-none
.hide-on-desktop-nav-view {
  @media (min-width: $width-max-mobile) {
    // stylelint-disable-next-line declaration-no-important
    display: none !important;
  }
}

// Forced to use important here, because makes no sense to write too specific selectors here
// stylelint-disable-next-line a11y/no-display-none
.hide-completely {
  // stylelint-disable-next-line declaration-no-important
  display: none !important;
}

.relative {
  position: relative;
}

body{
  &.fixedheader{
    padding-top: 176px;
  }
}

