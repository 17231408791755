// A hidden screen reader texts for readers, focus elements for
// vision impaired and other useful a11y CSS hacks.

#pojo-a11y-toolbar{
  z-index: 9999999 !important;
}

// Text meant only for screen readers.
@mixin screen-reader-text() {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);

  // doiuse-disable
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;

  // Many screen reader and browser combinations announce broken words as they would appear visually.
  // stylelint-disable-next-line declaration-no-important, max-line-length
  word-wrap: normal !important;

  &:focus-within {
    background-color: var(--color-white);
    border-radius: 0;
    box-shadow: 0 0 2px 2px rgb(22 22 22 / .6);
    clip: auto;
    clip-path: none;
    display: block;
    font-size: 1.7rem;
    font-weight: var(--font-weight-bold);
    height: auto;
    left: 5px;
    line-height: normal;
    padding: 15px 23px 14px;
    text-decoration: none;
    top: 5px;
    width: auto;
    z-index: 100000; // Above WP toolbar.
  }
}

.screen-reader-text {
  @include screen-reader-text();
}

.skip-link {
  margin: 5px;
}

// Visually distinct focus color on keyboard
a:focus,
input:focus,
button:focus,
select:focus,
textarea:focus,
div[tabindex]:focus {
  // Make sure every focusable element has opacity 100%
  opacity: 1;

  // Make sure it's not glued to the element
  outline-offset: 5px;
}

// Make focus a little more engaging
// @source https://twitter.com/argyleink/status/1387072095159406596
// @link https://codepen.io/argyleink/pen/JjEzeLp
@media (prefers-reduced-motion: no-preference) {
  *:focus {
    transition: outline-offset .25s ease;
  }
}

// External link icon
.external-link-icon {
  display: none !important;
  margin-left: .7rem;
  margin-right: .2rem;

  @media (max-width: $container-mobile) {
    height: 12px;
    margin-left: .4rem;
    transform: translateY(1px);
    width: 12px;
  }
}
