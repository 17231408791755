.developments-template-template-developments-available-homes{
    .development-nav-bar{
        .nav-bar-wrap{
            border-top: 2px solid var(--color-bronze);
            border-bottom: 2px solid var(--color-bronze);
            li{
                &.active{
                    &::before{
                        background-color: var(--color-bronze);
                    }
                }
            }
            select{
                background-color: var(--color-bronze);
            }
        }
    }
    .hero{
        min-height: 675px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-blend-mode: multiply;
        display: flex;
        align-items: center;
        @include media-breakpoint-down(xl) {
            min-height: auto;
            .container{
                padding: 0;
            }
        }
        h1{
            font-size: 64px;
            font-weight: bold;
            color: var(--color-white);
            text-align: center;
            max-width: 991px;
            margin: 0 auto 10px;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
                max-width: 100%;
                padding: 0 16px;
                margin-top: 80px;
            }
        }
        p{
            font-size: 20px;
            color: var(--color-white);
            text-align: center;
            max-width: 750px;
            margin: 0 auto 0;
            @include media-breakpoint-down(xl) {
                font-size: 16px;
                max-width: 100%;
                padding: 0 20px;
            }
        }
        .development-plot-filter{
            margin: 20px auto 0;
            @include media-breakpoint-down(xl) {
                background-color: var(--color-dark-blue);
                margin: 50px auto 0;
                padding: 25px 20px 30px;
            }
            p{
                font-family: var(--font-heading);
                font-size: 24px;
                font-weight: bold;
                color: var(--color-white);
                text-align: left;
                margin: 0 0 15px;
                max-width: 100%;
                @include media-breakpoint-down(xl) {
                    text-align: center;
                    margin: 0 0 25px;
                }
            }
            .filters-wrapper{
                border: 2px solid var(--color-bronze);
                padding: 30px;
                @include media-breakpoint-down(xl) {
                    border: none;
                    padding: 0;
                }
                label{
                    margin-bottom: 15px;
                    color: var(--color-white);
                    font-family: var(--font-heading);
                    font-weight: bold;
                    font-size: 18px;
                    @include media-breakpoint-down(xl) {
                        font-family: var(--font-paragraph);
                        font-weight: 500;
                        margin: 11px 0;
                    }
                }
                select{
                    background: transparent;
                    border: 1px solid var(--color-white);
                    padding: 15px 11px;
                    min-height: 56px;
                    color: var(--color-white);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%23fff'/%3E%3C/svg%3E%0A");
                    background-position: right 15px center;
                    background-repeat: no-repeat;
                    option{
                        color: var(--color-white);
                        background-color: var(--color-dark-blue);
                        border: 1px solid var(--color-white);
                    }
                }
                .btn{
                    min-height: 56px;
                    width: 100%;
                    @include media-breakpoint-down(xl) {
                        margin-top: 25px;
                    }
                }
            }
            .clear-filter{
                text-align: right;
                margin-top: 30px;
                @include media-breakpoint-down(xl) {
                    text-align: center;
                }
                .location-clear-btn{
                    box-shadow: none;
                    border: none;
                    background-color: transparent;
                    svg{
                        margin-right: 10px;
                    }
                    font-size: 16px;
                    text-decoration: underline;
                    color: var(--color-white);
                }
            }
        }
    }
    .available-plots{
        background-color: var(--color-off-white);
        padding: 100px 0 45px;
        @include media-breakpoint-down(xl) {
            padding: 30px 0 40px;
        }
        .sort-filter-wrapper{
            margin: 0px 0 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            flex-wrap: wrap;
            h3{
                margin-bottom: 15px;
                color: var(--color-dark-blue);
                font-family: var(--font-heading);
                font-weight: bold;
                font-size: 18px;
            }
            select{
                background: transparent;
                border: 1px solid var(--color-dark-blue);
                padding: 15px 11px;
                min-height: 56px;
                color: var(--color-dark-blue);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%230C293C'/%3E%3C/svg%3E%0A");
                background-position: right 15px center;
                background-repeat: no-repeat;
                max-width: 250px;
                option{
                    color: var(--color-dark-blue);
                    border: 1px solid var(--color-dark-blue);
                }
            }
        }
        .plot{
            padding: 0 12px;
            margin-bottom: 25px;
            &.sold{
                .image-wrapper{
                    img{
                        opacity: 0.4;
                    }
                    .image-label{
                        width: 100%;
                        background-color: var(--color-light-blue);
                        padding: 20px 0;
                        text-align: center;
                        p{
                            font-family: var(--font-heading);
                            color: var(--color-white);
                            font-weight: bold;
                            font-size: 16px;
                            letter-spacing: 1px;
                        }
                    }
                }
                .content-wrapper{
                    opacity: 0.4;
                    .btn{
                        pointer-events: none;
                        cursor: default;
                        text-decoration: none;
                    }
                }
            }
            .image-wrapper{
                position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    height: 310px;
                    object-fit: cover;
                }
                .image-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 17px 24px 17px 13px;
                    min-width: 307px;
                    background-repeat: no-repeat;
                    p{
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 16px;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .content-wrapper{
                background-color: var(--color-dark-blue);
                padding: 30px 30px 40px;
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 5px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                h4{
                    font-size: 24px;
                    color: var(--color-white);
                    margin-bottom: 10px;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        margin-bottom: 25px;
                    }
                }
                .plot-information,
                .price,
                .size{
                    margin-top: 20px;
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                    @include media-breakpoint-down(xxl) {
                        p{
                            font-size: 14px;
                        }
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                        p{
                            font-size: 16px;
                        }
                    }
                }
                .size{
                    margin-top: 0;
                }
                .button-row{
                    margin: 20px 0 0;
                    align-items: center;
                    @include media-breakpoint-down(xxl) {
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                }
                .btn{
                    padding: 10px 20px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
        }
        .btn__wrapper{
            text-align: center;
            margin-top: 45px;
            #load-more{
                background-color: var(--color-green);
                border: 1px solid var(--color-green);
                padding: 15px 115px;
                color: var(--color-white);
                text-transform: uppercase;
                font-size: 16px;
                font-weight: bold;
                font-family: var(--font-lato);
                text-decoration: none;
                cursor: pointer;
                &:hover{
                    background-color: var(--color-white);
                    color: var(--color-green);
                }
            }
        }
    }
}