.developments-template-template-developments-location{
    .development-nav-bar{
        .nav-bar-wrap{
            border-top: 2px solid var(--color-dark-pink);
            border-bottom: 2px solid var(--color-dark-pink);
            li{
                &.active{
                    &::before{
                        background-color: var(--color-dark-pink);
                    }
                }
            }
            select{
                background-color: var(--color-dark-pink);
                color: var(--color-dark-blue);
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_2' data-name='Polygon 2' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
            }
        }
    }
    .rank-math-breadcrumb{
        margin-top: -3rem;
        @include media-breakpoint-down(xxl) {
            
            padding-top: 4rem;
        }
    }
    .introduction{
        margin: 100px 0 80px;
        @include media-breakpoint-down(xl) {
            margin: 80px 0 30px;
            .container{
                padding: 0;
            }
        }
        .content-side{
            @include media-breakpoint-down(xl) {
                padding: 0 15px;
            }
            h1{
                font-size: 48px;
                font-weight: bold;
                color: var(--color-dark-blue);
                @include media-breakpoint-down(xl) {
                    font-size: 28px;
                }
            }
            p{
                strong{
                    font-size: 32px;
                    line-height: 32px;
                    color: var(--color-dark-blue);
                    @include media-breakpoint-down(xl) {
                        font-size: 16px;
                    }
                }
                font-size: 16px;
                &.location{
                    font-size: 36px;
                    font-weight: bold;
                    color: var(--color-dark-blue);
                    margin-bottom: 55px;
                    @include media-breakpoint-down(xl) {
                        font-size: 24px;
                        margin-bottom: 20px;
                    }
                }
            }
            .buttons{
                margin-top: 25px;
                align-items: center;
                .btn{
                    @include media-breakpoint-down(lg) {
                        width: 100%;
                    }
                }
                .interactive{
                    font-family: var(--font-paragraph);
                    font-size: 24px;
                    color: var(--color-light-blue);
                    text-decoration-color: var(--color-light-blue);
                    position: relative;
                    @include media-breakpoint-down(xl) {
                        display: none;
                    }
                    @include media-breakpoint-up(xl) {
                        &::after{
                            content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='27' height='40' viewBox='0 0 27 40'%3E%3Cpath id='Path' d='M23.545,5V38.165l9.271-9.442,2.477,2.524L21.793,45,8.293,31.246l2.477-2.524,9.271,9.442V5Z' transform='translate(-8.293 -5)' fill='%23006e88'/%3E%3C/svg%3E%0A");
                            height: 40px;
                            display: inline-block;
                            position: absolute;
                            margin-left: 70px;
                        }
                    }
                    
                }
            }
        }
        .info-side{
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='545' height='313' viewBox='0 0 545 313'%3E%3Cpath id='Path_457' data-name='Path 457' d='M565,3669.754v313H20Z' transform='translate(-20 -3669.754)' fill='%23f1cdc6'/%3E%3C/svg%3E%0A");
            background-position: bottom;
            background-size: contain;
            background-repeat: no-repeat;
            padding: 0;
            .information-box{
                margin: 0 40px 40px 0;
                padding: 35px 25px;
                border: 2px solid var(--color-bronze);
                background-color: var(--color-white);
                @include media-breakpoint-down(xl) {
                    margin: 40px 16px 20px;
                    padding: 20px 16px;
                }
                h2{
                    font-size: 32px;
                    color: var(--color-dark-blue);
                    font-weight: bold;
                    margin-bottom: 15px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                .information-row{
                    border-top: 2px solid var(--color-bronze);
                    padding: 30px 0;
                    align-items: center;
                    @include media-breakpoint-down(xl) {
                        padding: 15px 0;
                    }
                    @include media-breakpoint-down(lg) {
                        padding: 20px 0;
                        &.mobile{
                            padding: 20px 0 10px;
                            &:last-of-type{
                                border: none;
                                padding: 0;
                            }
                            .col-12{
                                padding: 0;
                                .btn{
                                    padding: 15px 34px;
                                }
                            }
                        }
                    }
                    p{
                        font-size: 24px;
                        color: var(--color-dark-blue);
                        margin: 0;
                        @include media-breakpoint-down(xxl) {
                            font-size: 20px;
                        }
                        @include media-breakpoint-down(xl) {
                            font-size: 16px;
                        }
                        a{
                            color: var(--color-dark-blue);
                            font-weight: 500;
                        }
                    }
                    .btn{
                        width: 300px;
                        @include media-breakpoint-down(xl) {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    .seperators{
        margin: 0 250px;
        border-top: 2px solid var(--color-dark-pink);
        opacity: 1;
        @include media-breakpoint-down(xl) {
            display: block;
            margin: 0 20px;
        }
    }
    .key-features{
        padding: 64px 0 30px;
        @include media-breakpoint-down(xl) {
            padding: 30px 0 30px;
            .container{
                padding: 0;
            }
        }
        .features-wrapper{
            background-color: var(--color-lighter-blue);
            padding: 50px;
            @include media-breakpoint-down(xl) {
                padding: 20px;
            }
            .content-side{
                @include media-breakpoint-down(xl) {
                    order: 12;
                    padding: 30px 20px;
                }
                @include media-breakpoint-down(lg) {
                    order: 12;
                    padding: 30px 0;
                }
                h3{
                    font-size: 48px;
                    font-weight: bold;
                    margin-bottom: 25px;
                    @include media-breakpoint-down(xl) {
                        font-size: 28px;
                        margin-bottom: 20px;
                    }
                }
                .features{
                    .row{
                        margin: 0 0 30px;
                        @include media-breakpoint-down(xl) {
                            margin: 0 0 15px;
                        }
                        svg{
                            @include media-breakpoint-down(xl) {
                                max-width: 24px;
                            } 
                        }
                        p{
                            font-size: 20px;
                            font-weight: 500;
                            color: var(--color-dark-blue);
                            margin: 0;
                            @include media-breakpoint-down(xl) {
                                font-size: 16px;
                            } 
                        }
                    }
                }
                .btn{
                    margin-bottom: 16px;
                    min-width: 380px;
                    @include media-breakpoint-down(xl) {
                        min-width: 100%;
                        max-width: 100%;
                    } 
                    &:last-of-type{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        &:hover{
                            background-color: var(--color-white);
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                        }
                    }
                }
            }
            .image-side{
                @include media-breakpoint-down(xl) {
                    order: 1;
                }
                img{
                    max-width: 100%;
                    height: 640px;
                    object-fit: cover;
                    object-position: center;
                    @include media-breakpoint-down(lg) {
                        height: 250px;
                        width: 100%;
                    }
                }
            }
        }
    }
    .locations{
        padding: 30px 0 50px;
        @include media-breakpoint-down(xl) {
            padding: 0px;
        }
        @include media-breakpoint-down(lg) {
            .container{
                padding: 0;
            }
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 10px;
            @include media-breakpoint-down(lg) {
                padding: 0 20px;
                font-size: 28px;
            }
        }
        p{
            font-size: 16px;
            margin: 0;
            max-width: 50%;
            @include media-breakpoint-down(xl) {
                max-width: 100%;
            }
            @include media-breakpoint-down(lg) {
                padding: 0 20px;
            }
        }
        .filter-side{
            padding-left: 0;
            @include media-breakpoint-down(lg) {
                padding: 0 20px 20px;
            }
            .filters-wrapper{
                border: 2px solid var(--color-dark-blue);
                background-color: var(--color-off-white);
                position: relative;
                @include media-breakpoint-down(lg) {
                    background-color: var(--color-white);
                    border: none;
                }
                .filters-heading{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    padding: 30px 40px;
                    border-bottom: 2px solid var(--color-dark-blue);
                    @include media-breakpoint-down(lg) {
                        border-top: 2px solid var(--color-dark-blue);
                        border-left: 2px solid var(--color-dark-blue);
                        border-right: 2px solid var(--color-dark-blue);
                        border-bottom: none;
                    }
                    
                    svg{
                        margin-right: 20px;
                        @include media-breakpoint-down(lg) {
                            height: 20px;
                            width: 20px;
                        }
                    }
                    font-family: var(--font-heading);
                    color: var(--color-dark-blue);
                    font-size: 24px;
                    font-weight: bold;
                    @include media-breakpoint-down(lg) {
                        padding: 14px 20px;
                        font-size: 14px;
                        font-family: var(--font-lato);
                        text-transform: uppercase;
                    }
                    @include media-breakpoint-down(lg) {
                        &::after{
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='9' height='6' viewBox='0 0 9 6'%3E%3Cpath id='Polygon_18' data-name='Polygon 18' d='M4.5,0,9,6H0Z' transform='translate(9 6) rotate(180)' fill='%230c293c'/%3E%3C/svg%3E%0A");
                            content: "";
                            height: 6px;
                            width: 9px;
                            position: absolute;
                            right: 20px;
                        }
                    }
                }
                .filters{
                    padding: 30px 40px;
                    border-bottom: 2px solid var(--color-dark-blue);
                    @include media-breakpoint-down(lg) {
                        height: 0px;
                        overflow: hidden;
                        padding: 0px;
                        transition: 0.2s all;
                        position: absolute;
                        background-color: var(--color-off-white);
                        width: 100%;
                        z-index: 2;
                        &.open{
                            height: auto;
                            padding: 20px;
                            transition: 0.2s all;
                            border: 2px solid var(--color-dark-blue);
                        }
                    }
                    .row{
                        display: flex;
                        align-items: center;
                        margin: 0 0 16px;
                        @include media-breakpoint-down(lg) {
                            margin: 0 0 20px;
                        }
                        &:last-of-type{
                            margin: 0;
                        }
                        input[type="checkbox"]{
                            width: 21px;
                            height: 21px;
                            background-color: transparent;
                            display: grid;
                            border: 1px solid var(--color-grey);
                            margin: 0 auto;
                            &:checked{
                                &::before{
                                    height: 10px;
                                    width: 12px;
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12.541' height='9.491' viewBox='0 0 12.541 9.491'%3E%3Cpath id='Path_238' data-name='Path 238' d='M19.687,9.6l-8.208,8.541L7.146,13.63l.914-.951,3.419,3.558,7.295-7.59Z' transform='translate(-7.146 -8.646)' fill='%230c293c' fill-rule='evenodd'/%3E%3C/svg%3E%0A");
                                }
                            }
                        }
                        svg{
                            max-width: 100%;
                            @include media-breakpoint-down(lg) {
                                max-width: 32px;
                                max-height: 32px;
                            }
                        }
                        p{
                            margin: 0;
                            font-size: 20px;
                            color: var(--color-dark-blue);
                            @include media-breakpoint-down(lg) {
                                font-size: 16px;
                            }
                        }
                    }
                }
                .filters-clear{
                    padding: 24px 40px;
                    display: block;
                    @include media-breakpoint-down(lg) {
                        display: none;
                    }
                    &.mobile{
                        display: none;
                        padding: 30px 0 0;
                        @include media-breakpoint-down(lg) {
                            display: block;
                        }
                    }
                    
                    .clear-btn{
                        box-shadow: none;
                        border: none;
                        background-color: transparent;
                        svg{
                            margin-right: 10px;
                        }
                        font-size: 16px;
                        text-decoration: underline;
                        color: var(--color-dark-blue);
                    }
                }
            }
        }
        .map-side{
            padding-right: 0;
            position: relative;
            @include media-breakpoint-down(lg) {
                padding: 0;
            }
            .google-verification{
                position: absolute;
                width: calc(100% - calc(var(--bs-gutter-x) * .5));
                bottom: 0;
                left: calc(var(--bs-gutter-x) * .5);
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    background-color: var(--color-dark-blue);
                    padding: 20px;
                    color: var(--color-white);
                    text-decoration: underline;
                    @include media-breakpoint-down(xl) {
                        font-size: 10px;
                        padding: 10px;
                    }
                }
                img{
                    max-width: 115px;
                }
            }
            #mapContainer{
                min-height: 100%;
                @include media-breakpoint-down(lg) {
                    min-height: 684px;
                }
                .gm-style-iw{
                    border-radius: 0;
                    background: var(--color-light-blue);
                    padding: 0;
                    max-width: 330px !important;
                    min-width: 265px !important;
                    max-height: none !important;
                    overflow: visible !important;
                    .gm-style-iw-d{
                        overflow: auto !important;
                        .custom-info-window{
                            padding: 35px 40px 40px;
                            h3{
                                color: var(--color-white);
                                font-weight: bold;
                                font-size: 24px;
                                margin-bottom: 20px;
                            }
                            h4{
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                svg{
                                    margin-right: 20px;
                                }
                                color: var(--color-white);
                                font-weight: bold;
                                font-size: 24px;
                                margin-bottom: 15px;
                            }
                            p{
                                font-size: 20px;
                                color: var(--color-white);
                                font-family: var(--font-paragraph);
                                font-weight: normal;
                                max-width: none;
                            }
                        }
                    }
                    button{
                        &.gm-ui-hover-effect{
                            background: var(--color-light-blue) !important;
                            top: -15px !important;
                            right: -15px !important;
                            width: 47px !important;
                            height: 47px !important;
                            border-radius: 100px !important;
                            opacity: 1 !important;
                            span{
                                -webkit-mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='23.729' height='23.732' viewBox='0 0 23.729 23.732'%3E%3Cpath id='Path_186' data-name='Path 186' d='M152.22,129.359a1.469,1.469,0,0,0-2.085,0L140.79,138.7l-9.345-9.345a1.475,1.475,0,0,0-2.085,2.085l9.345,9.345-9.345,9.345a1.479,1.479,0,0,0,1.037,2.522,1.438,1.438,0,0,0,1.037-.437l9.345-9.345,9.345,9.345a1.484,1.484,0,0,0,1.037.437,1.421,1.421,0,0,0,1.037-.437,1.47,1.47,0,0,0,0-2.085l-9.324-9.345,9.345-9.345A1.47,1.47,0,0,0,152.22,129.359Z' transform='translate(-128.925 -128.925)' fill='%23fff'/%3E%3C/svg%3E%0A") !important;
                                width: 23px !important;
                                height: 23px !important;
                                margin: 0 auto !important;
                                background-color: var(--color-white) !important;
                            }
                        }
                    }
                }
                .gm-style-iw-tc{
                    &::after{
                        background: var(--color-light-blue);
                    }
                }
            }

        }
        .button{
            margin-top: 50px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                margin-top: 0;
                padding: 40px 20px;
            }
            .btn{
                min-width: 390px;
                @include media-breakpoint-down(xl) {
                    min-width: 100%;
                }
            }
        }
    }

    .traveltimes{
        background-color: var(--color-lighter-blue);
        padding: 30px 0 50px;
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 10px;
            text-align: center;
            color: var(--color-dark-blue);
            @include media-breakpoint-down(lg) {
                padding: 0 20px;
                font-size: 28px;
            }
        }
        p{
            font-size: 16px;
            margin: 0 auto;
            max-width: 50%;
            text-align: center;
            color: var(--color-dark-blue);
            @include media-breakpoint-down(xl) {
                max-width: 100%;
            }
        }
        .nearest-points{
            padding-top: 2rem;
            .nearest-point{
                @include media-breakpoint-down(xl) {
                    border: none !important;
                    padding: 0 0 40px !important;
                }
                &:nth-child(2){
                    border-left: 2px solid var(--color-dark-blue);
                }
                &:nth-child(3){
                    border-left: 2px solid var(--color-dark-blue);
                }
                &:nth-child(4){
                    border-left: 2px solid var(--color-dark-blue);
                }
                h3{
                    font-weight: bold;
                    font-size: 24px;
                    color: var(--color-dark-blue);
                    margin-bottom: 10px;
                    text-align: right;
                    @include media-breakpoint-down(xl) {
                        margin-left: 30%;
                    }
                }
                svg{
                    max-width: 90%;
                    max-height: 50px;
                    @include media-breakpoint-down(xl) {
                        max-width: 90px;
                    }
                }
                p{
                    max-width: 100%;
                    &.location{
                        font-size: 19px;
                        font-weight: 500;
                        text-align: right;
                        @include media-breakpoint-down(xxl) {
                            font-size: 15px;
                        }
                    }
                    &.time{
                        text-align: right;
                        span{
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
        .accordion{
            margin-top: 20px;
            padding: 0 3rem;
            @include media-breakpoint-down(xl) {
                margin-top: 0px;
                padding: 0 10rem;
            }
            @include media-breakpoint-down(md) {
                padding: 0;
            }
            .accordion-item{
                border: none;
                background-color: transparent;
                color: var(--color-dark-blue);
                border-radius: 0;
                .accordion-header{
                    border-bottom: 2px solid var(--color-dark-blue);
                    .accordion-button{
                        border: none;
                        background-color: transparent;
                        color: var(--color-dark-blue);
                        border-radius: 0;
                        font-family: var(--font-heading);
                        font-size: 24px;
                        font-weight: bold;
                        padding: 20px 0;
                        @include media-breakpoint-down(xl) {
                            font-size: 18px;
                            padding: 10px 0;
                        }
                        &::after{
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='Path_213' data-name='Path 213' d='M173.15,128.37a1.5,1.5,0,1,0-1.975,2.259L181.884,140l-10.71,9.371a1.5,1.5,0,0,0,1.975,2.259l12-10.5a1.5,1.5,0,0,0,0-2.259Zm0,0' transform='translate(151.999 -170.662) rotate(90)' fill='%230C293C'/%3E%3C/svg%3E%0A");
                        }
                        &:not(.collapsed){
                            background-color: transparent;
                            box-shadow: none;
                            &::after{
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='15' viewBox='0 0 24 15'%3E%3Cpath id='Path_212' data-name='Path 212' d='M173.15,128.37a1.5,1.5,0,1,0-1.975,2.259L181.884,140l-10.71,9.371a1.5,1.5,0,0,0,1.975,2.259l12-10.5a1.5,1.5,0,0,0,0-2.259Zm0,0' transform='translate(-127.999 185.662) rotate(-90)' fill='%230C293C'/%3E%3C/svg%3E%0A");
                                transform: none;
                            }
                        }
                    }
                }
                .accordion-collapse{
                    border-bottom: 2px solid var(--color-dark-blue);
                    .accordion-body{
                        padding: 35px 0 0;
                        @include media-breakpoint-down(xl) {
                            padding: 20px 0 0;
                        }
                        &.transport-type{
                            p{
                                text-align: left;
                                margin-bottom: 2rem;
                            }
                            h3{
                                color: var(--color-dark-blue);
                                padding-bottom: 20px;
                                border-bottom: 2px solid var(--color-green);
                                margin-bottom: 35px;
                                font-weight: bold;
                            }
                            p{
                                max-width: 100%;
                            }
                            .times-row{
                                margin-bottom: 30px;
                                .times{
                                    .hours,
                                    .minutes{
                                        text-align: left;
                                        p{
                                            margin: 0;
                                        }
                                        span{
                                            font-weight: 500;
                                            font-size: 30px;
                                        }
                                    }
                                }
                                .locations{
                                    padding: 0 20px;
                                    h4{
                                        color: var(--color-dark-blue);
                                        font-size: 18px;
                                        text-align: left;
                                        font-weight: 500;
                                        margin: 0;
                                    }
                                    p{
                                        color: var(--color-dark-blue);
                                        text-align: left;
                                        margin: 0;
                                    }
                                }
                            }
                        }
                        &.underground-times{
                            .image-wrapper{
                                text-align: left;
                                @include media-breakpoint-down(lg) {
                                    text-align: center;
                                }
                                svg{
                                    max-width: 150px;
                                    margin-bottom: 40px;
                                }
                            }
                            .u-times-wrapper{
                                border-left: 2px solid var(--color-dark-blue);
                                padding-top: 10px;
                                .start-point{
                                    position: relative;
                                    &::before{
                                        content: "";
                                        height: 20px;
                                        width: 20px;
                                        background-color: var(--color-dark-blue);
                                        border-radius: 100px;
                                        display: block;
                                        position: absolute;
                                        top: 50%;
                                        left: -11px;
                                        transform: translateY(-50%);
                                    }
                                    .content{
                                        padding-left: 20px;
                                        h3{
                                            color: var(--color-dark-blue);
                                            font-weight: bold;
                                            margin: 0;
                                        }
                                    }
                                }
                                .point{
                                    position: relative;
                                    margin: 25px 0;
                                    &::before{
                                        content: "";
                                        height: 20px;
                                        width: 20px;
                                        background-color: var(--color-dark-blue);
                                        border: 2px solid var(--color-dark-blue);
                                        border-radius: 100px;
                                        display: block;
                                        position: absolute;
                                        top: 50%;
                                        left: -11px;
                                        transform: translateY(-50%);
                                    }
                                    .content{
                                        padding-left: 20px;
                                        h4{
                                            color: var(--color-dark-blue);
                                            font-weight: bold;
                                            margin: 0;
                                        }
                                        p{
                                            max-width: 100%;
                                            text-align: left;
                                            margin: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        
    }
    .plots{
        background-color: var(--color-off-white);
        padding: 65px 0 50px;
        background-image: none;
        @include media-breakpoint-down(xl) {
            padding: 30px 0px;
        }
        h2{
            font-size: 48px;
            font-weight: bold;
            margin-bottom: 25px;
            text-align: center;
            @include media-breakpoint-down(xl) {
                font-size: 28px;
            }
        }
        .plot{
            padding: 0 12px;
            @include media-breakpoint-down(lg) {
                padding: 0 0 24px;
            }
            .image-wrapper{
                position: relative;
                img{
                    width: 100%;
                    max-width: 100%;
                    height: 310px;
                    object-fit: cover;
                }
                .image-label{
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding: 17px 24px 17px 13px;
                    min-width: 307px;
                    background-repeat: no-repeat;
                    p{
                        font-family: var(--font-heading);
                        font-weight: bold;
                        color: var(--color-white);
                        font-size: 16px;
                        margin: 0;
                        text-transform: uppercase;
                        letter-spacing: 1px;
                        @include media-breakpoint-down(xl) {
                            font-size: 10px;
                        }
                    }
                }
            }
            .content-wrapper{
                background-color: var(--color-dark-blue);
                padding: 30px 30px 40px;
                h3{
                    font-size: 32px;
                    font-weight: bold;
                    color: var(--color-white);
                    margin-bottom: 5px;
                    @include media-breakpoint-down(xl) {
                        font-size: 20px;
                    }
                }
                h4{
                    font-size: 24px;
                    color: var(--color-white);
                    margin-bottom: 10px;
                    @include media-breakpoint-down(xl) {
                        font-size: 14px;
                        margin-bottom: 25px;
                    }
                }
                .price{
                    svg{
                        margin: 0 18px 0 0px !important;
                    }
                }
                .plot-information,
                .price,
                .size{
                    margin-top: 20px;
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                    @include media-breakpoint-down(xxl) {
                        p{
                            font-size: 14px;
                        }
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                        p{
                            font-size: 16px;
                        }
                    }
                }
                .size{
                    margin-top: 0;
                }
                .button-row{
                    margin: 20px 0 0;
                    align-items: center;
                    @include media-breakpoint-down(xxl) {
                        margin-top: 10px;
                    }
                    @include media-breakpoint-down(xl) {
                        margin-top: 8px;
                    }
                }
                .columns{
                    border-left: 5px solid var(--color-dark-pink);
                    padding: 0 0 0 15px;
                    display: flex;
                    align-items: center;
                    svg{
                        margin-right: 16px;
                    }
                    p{
                        font-size: 20px;
                        color: var(--color-white);
                        margin: 0;
                    }
                }
                .excerpt{
                    margin: 20px 0 40px;
                    color: var(--color-white);
                }
                .btn{
                    padding: 10px 40px;
                    @include media-breakpoint-down(xl) {
                        margin-top: 20px;
                        width: 100%;
                    }
                    &.btn-developments{
                        background-color: var(--color-light-blue);
                        border: 1px solid var(--color-light-blue) !important;
                        width: 100%;
                        &:hover{
                            border: 1px solid var(--color-light-blue) !important;
                            color: var(--color-light-blue);
                            background-color: var(--color-white);
                        }
                    }
                }
            }
        }
        .button{
            text-align: center;
            @include media-breakpoint-down(lg) {
                display: none;
            }
            .btn{
                &.main{
                    max-width: 340px;
                    margin-top: 50px;
                    background-color: var(--color-light-blue);
                    color: var(--color-white);
                    border: 1px solid var(--color-light-blue) !important;
                    &:hover{
                        background-color: var(--color-white);
                        color: var(--color-light-blue);
                    }
    
                }
            }
        }
    }
}