// Self-hosted fonts
// Remember to add these also to gutenberg/variables/_font-family.scss
// with correct paths

// Regular fonts
 @include font('Inter', '../../fonts/inter-400', 400);
 @include font('Inter', '../../fonts/inter-400-italic', 400, italic);
 @include font('Inter', '../../fonts/inter-500', 500);
 @include font('Inter', '../../fonts/inter-600', 600);
 @include font('Inter', '../../fonts/inter-700', 700);

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/Larsseit.otf') format("opentype");
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/Larsseit-Light.otf') format("opentype");
    font-weight: lighter;
    font-style: normal;
}

@font-face {
    font-family: 'Larsseit';
    src: url('../fonts/LarsseitMedium.otf') format("opentype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Santral';
    src: url('../fonts/Santral Light Italic.otf') format("opentype");
    font-weight: lighter;
    font-style: italic;
}

@font-face {
    font-family: 'Santral';
    src: url('../fonts/santral-bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Lora';
    src: url('../fonts/Lora/static/Lora-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Bold.ttf') format("truetype");
    font-weight: bold;
    font-style: normal;
}