// Alternative style: https://i.imgur.com/A8gUyu2.png
// stylelint-disable a11y/font-size-is-readable
.navigation:not(.post-navigation) .nav-links {
  align-items: flex-start;
  display: flex;
  font-family: var(--font-heading);
  font-size: 14px;
  justify-content: flex-start;
  margin-top: 4rem;
  overflow: hidden;
  text-align: left;
  width: 100%;

  a {
    text-decoration: none;
  }
}

.page-numbers {
  align-items: center;
  background-color: var(--color-white);
  border-bottom: 2px solid var(--color-blue-chalk);
  border-left: 1px solid var(--color-blue-chalk);
  border-right: 1px solid var(--color-blue-chalk);
  border-top: 2px solid var(--color-blue-chalk);
  color: var(--color-pagination-text);
  display: flex;
  font-weight: var(--font-weight-medium);
  height: 35px;
  justify-content: center;
  margin-left: 0;
  margin-right: 0;
  transition: all $transition-duration;
  width: 35px;

  &:last-child {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-left-radius: 0;
    border-top-right-radius: 4px;
  }

  &:first-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 0;
  }

  @media (max-width: 600px) {
    height: 26px;
    width: 26px;
  }
}

a.page-numbers:hover,
a.page-numbers:focus {
  background-color: var(--color-pagination-background-hover);
  border-color: var(--color-pagination-border-hover);
  color: var(--color-pagination-text-hover);
}

.page-numbers.current {
  background-color: var(--color-pagination-background-current);
  border-color: var(--color-pagination-border-current);
  color: var(--color-pagination-text-current);
}

// Remove this if you are using version with next/prev
.page-numbers.next,
.page-numbers.prev {
  padding-left: 20px;
  padding-right: 20px;
  width: auto;

  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
