// Gravity forms styles >= 2.5
// Please disable CSS in /admin.php?page=gf_settings

// WCAG 2.0 errors
// stylelint-disable selector-class-pattern
div.validation_error {
  background-color: var(--color-error);
  color: var(--color-white);
  font-size: var(--font-size-17);
  font-weight: var(--font-weight-bold);
  margin-bottom: var(--form-row-gap);
  padding: 2rem;

  &:focus {
    color: var(--color-white);
  }
}

body *[aria-invalid="true"] {
  border-color: var(--color-error);
}

.validation_message {
  color: var(--color-error);
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-regular);
}

.validation_list {
  list-style: none;
  margin-bottom: var(--form-row-gap);
  margin-left: 0;
  padding: 0;

  a {
    color: var(--color-error);
    text-decoration: none;
  }

  li {
    color: var(--color-error);
    font-weight: var(--font-weight-bold);
  }
}

// Form screen reader texts
.gform_required_legend,
.sr-only {
  @include screen-reader-text();
}

// Default required label
.gfield_required.gfield_required_asterisk {
  font-size: var(--font-size-required-asterisk-label);
}

.gfield_required.gfield_required_asterisk,
.gfield_required.gfield_required_text {
  color: var(--color-required-label-text);
}

.gfield_required.gfield_required_text {
  font-size: var(--font-size-required-text-label);
}

// Gravity forms minimal default styles
.gform_wrapper > form {
  display: grid;
  grid-column-gap: var(--form-column-gap);
  grid-row-gap: var(--form-row-gap);
}

.gform-body {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  // stylelint-disable selector-not-notation
  textarea,
  input:not([type="checkbox"]):not([type="radio"]) {
    width: 100%;
  }

  // Fix textarea not full width
  .ginput_container.ginput_container_textarea {
    display: flex;
  }

  // Checkboxes & radio buttons
  .gfield_radio,
  .gfield_checkbox {
    display: grid;
    gap: 3rem;
  }

  .gchoice {
    display: flex;
    gap: 1rem;
  }

  .gfield_label {
    display: inline-flex;
    gap: var(--form-required-gap);
    margin-bottom: 1rem;
    margin-top: 1rem;
    padding-inline-end: 0;
    padding-inline-start: 0;
  }

  // 2.5 built-in multi column support
  .gform_fields {
    display: grid;
    grid-column-gap: var(--form-column-gap);
    grid-row-gap: var(--form-row-gap);
    // doiuse-disable
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(auto-fill, auto);
    width: 100%;
  }

  .gfield {
    border: 0;
    grid-column: 1 / -1;
    padding: 0;
  }

  .gfield.gfield--width-full {
    grid-column: span 12;
  }

  .gfield.gfield--width-eleven-twelfths {
    grid-column: span 11;
  }

  .gfield.gfield--width-five-sixths {
    grid-column: span 10;
  }

  .gfield.gfield--width-three-quarter {
    grid-column: span 9;
  }

  .gfield.gfield--width-two-thirds {
    grid-column: span 8;
  }

  .gfield.gfield--width-seven-twelfths {
    grid-column: span 7;
  }

  .gfield.gfield--width-half {
    grid-column: span 6;
  }

  .gfield.gfield--width-five-twelfths {
    grid-column: span 5;
  }

  .gfield.gfield--width-third {
    grid-column: span 4;
  }

  .gfield.gfield--width-quarter {
    grid-column: span 3;
  }

  @media (max-width: 640px) {
    .gform_fields {
      grid-column-gap: 0;
    }

    .gfield:not(.gfield--width-full) {
      grid-column: 1 / -1;
    }
  }
}

// Hide SPAM Honeypot fields
// stylelint-disable-next-line a11y/no-display-none
.gform_validation_container {
  display: none;
}

// Default confirmation message styles
.gform_confirmation_message {
  background-color: transparent;
  border: 2px solid var(--color-success);
  color: var(--color-success);
  font-size: var(--font-size-paragraph);
  font-weight: var(--font-weight-semibold);
  padding: 2rem;
}
