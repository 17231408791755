// CSS variables
// Define names:
// For AAA: https://www.color-blindness.com/color-name-hue/
// For AA: https://chir.ag/projects/name-that-color/
// ------------------------------------------------------
:root {
  // Brand color pool
  --color-black: #000000;
  --color-white: #fff;
  --color-light-grey: #3C3C3B;
  --color-dark-blue: #0C293C;
  --color-light-blue: #006E88;
  --color-lighter-blue: #CCE2E7;
  --color-green: #168F4F;
  --color-grey: #425461;
  --color-pink: #EEC0B8;
  --color-dark-pink: #E9B1A9;
  --color-bronze: #BD8052;
  --color-off-white: #FCF2F1;

  // Main element colors
  --color-heading: var(--color-dark-blue);
  --color-main: var(--color-dark-blue);
  --color-paragraph: var(--color-light-grey);

  // Modern link
  --color-link-border-bottom: var(--color-dark-blue);
  --color-link-border-focus: var(--color-dark-blue);
  --color-link-comment: var(--color-dark-blue);
  --color-link-comment-time-anchor: var(--color-dark-blue);
  --color-link-edit-background-hover: var(--color-dark-blue);
  --color-link-edit-border: var(--color-dark-blue);
  --color-link-edit-text: color.adjust(#eaf1f8, -20%);
  --color-link-edit-text-hover: var(--color-dark-blue);
  --color-link-text: var(--color-dark-blue);
  --color-link-text-focus: var(--color-black);
  --color-link-text-hover: var(--color-black);
  --color-link-background-hover: var(--color-dark-blue);

  // Element colors
  --color-background-code-block: var(--color-dark-blue);
  --color-background-code-inline: #f9f2f4;
  --color-background-default: #f0f0f0;
  --color-background-shade: #03061b;
  --color-background-table-cell-odd: var(--color-white);
  --color-background-table-cell-even: #f3f3f3;
  --color-background-top: #f5f5f5;
  --color-background-top-hover: #f0f0f0;
  --color-background-tag: var(--color-dark-blue);
  --color-blockquote: var(--color-dark-blue);
  --color-comment-text: var(--color-dark-blue);
  --color-comment-author-text: var(--color-dark-blue);
  --color-tag-border: rgb(6 22 41 / .1);
  --color-tag-border-hover: rgb(6 22 41 / .4);
  --color-tag-text: var(--color-black);
  --color-category-border: #cdcfd4;
  --color-category-text: var(--color-black);
  --color-caption: #5c5c6a;
  --color-code-block: #c7254e;
  --color-code-text: #f5f5f5;
  --color-kbd-border: #ccc;
  --color-kbd-background: var(--color-dark-blue);
  --color-pagination-text: var(--color-dark-blue);
  --color-pagination-background-hover: var(--color-dark-blue);
  --color-pagination-border-hover: var(--color-dark-blue);
  --color-pagination-text-hover: var(--color-white);
  --color-pagination-background-current: var(--color-dark-blue);
  --color-pagination-border-current: var(--color-dark-blue);
  --color-pagination-text-current: var(--color-white);
  --color-top-arrow-dark: var(--color-black);
  --color-top-arrow-light: var(--color-white);
  --color-error-block-border: #adb2ad; // Color picked from ACF
  --color-error-block-background: #f9f9f9; // Color picked from ACF

  // Element states
  --color-error: #cc2e2e;
  --color-success: #21aa14;
  --color-warning: #cc752e;
  --color-focus-outline: #7b46e1;
}
