// Error 404 title
.block-error-404 {
  .container {
    @media (min-width: $container-ipad-landscape) {
      padding-bottom: 10%;
      padding-top: 10%;
    }
  }

  h1 {
    font-size: clamp-calc($container-mobile, 1600px, 42px, 200px);
    margin-bottom: 2rem;
  }
}
