// CSS Variables for responsive fonts
:root {
  // Heading font sizes
  --font-size-hero: 52px;
  --font-size-h1: 40px;
  --font-size-h2: 30px;
  --font-size-h3: 24px;
  --font-size-h4: 20px;
  --font-size-h5: 16px;
  --font-size-h6: 14px;

  // Paragraph font sizes
  --font-size-14: 14px;
  --font-size-15: 15px;
  --font-size-16: 16px;
  --font-size-17: 17px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-22: 22px;
  --font-size-28: 28px;

  // Element font sizes
  --font-size-paragraph: var(--font-size-18);
  --font-size-nav-toggle-label: 15px;
  --font-size-captions: 15px;

  // Line-heights
  --line-height-heading: 1.5;
  --line-height-core-heading: 1.23;
  --line-height-paragraph: 1.7;

  // Font weights
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semibold: 600;
  --font-weight-bold: 700;
  --font-weight-paragraph: var(--font-weight-regular);
  --font-weight-heading: var(--font-weight-bold);

  // Heading font sizes in mobile
  @media (max-width: $container-mobile) {
    --font-size-hero: 38px;
    --font-size-h1: 32px;
    --font-size-h2: 24px;
    --font-size-h3: 21px;
    --font-size-h4: 18px;
    --font-size-h5: 14px;
    --font-size-h6: 12px;
    --font-size-paragraph: 16px;
  }

  // Element font sizes in tiny phones like iPhone 5S
  @media (max-width: 420px) {
    --font-size-16: 14px;
    --font-size-nav-toggle-label: 13px;
  }
}
